<template>
    <div class="panel has-text-weight-semibold"  id="ReportContent">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-11 has-text-centered spaced-text-8">MONTH END SUMMARY</div>                    
                <div class="column has-text-right is-1" v-if="!assetItemsNotFound">
                    <b-button  title="Print" type="is-dark" outlined class="is-small" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button>
                </div> 
            </div>
        </div>

        <div class="box" v-if="assetItemsNotFound && showErrorMessage">
            <b-message 
            title="Request Failed" 
            type="is-danger" 
            has-icon 
            :closable="errorMessageClosable"
            aria-close-label="Close message">
            Cannot process the request. Please contact VEECLi Support for assistance. ({{ errorCode.response.status }})
        </b-message>
            <b-message is-danger></b-message>
        </div>
        <div class="box">
            <div class="columns" >
                <!-- Column 1 -->
                <div class="column is-half">
                    <b-field  grouped>
                        <b-datepicker
                            custom-class="month-date-selector"
                            ref="monthSelector"
                            type="month"
                            :disabled="isEditing"
                            v-model="monthSelected" 
                            :mobile-native="false"
                            icon="calendar"
                            :min-date="minDate"
                            :max-date = "maxDate"
                            :editable="isMonthFieldEditable"
                            :date-formatter="monthFormatter"
                            >
                        </b-datepicker>
                        <b-button
                            @click="$refs.monthSelector.toggle()"
                            :disabled="isEditing"
                            icon-left="calendar"
                            type="is-primary" />
                    </b-field>
                    
                </div>

            </div>
            <br>
            <div class="columns">
                <div class="column has-text-centered has-text-weight-bold is-uppercase is-size-4 spaced-text-8">
                    {{ monthSelected | formatMonthYear}}
                </div>                
            </div>
            <div class="columns" v-if="isEditingDisabled">
                <div class="column has-text-centered has-text-weight-bold is-size-5">
                    <b-notification 
                        type="is-info is-light"
                        :closable="editDisabledMessageClosable" 
                        >
                        Future Month End Information Exists. Not allowed to modify {{ monthSelected | formatMonthYear}} closing information.
                    </b-notification>
                </div>                
            </div>
            <br>            
            <div class="columns">
                <div class="column is-half">
                    <div class="panel">
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8"> PHYSICAL INVENTORY </div>
                            </div>
                        </div>

                        <div class="panel-block">
                            <section class="control">
                            <br>
                            <div class="columns">
                                <div class="column is-8">
                                    Physical Inventory Date
                                </div>
                                <div class="column">
                                    <b-field > 
                                        <b-datepicker
                                            ref="physicalInventoryDate"
                                            :mobile-native="isMobileNativeDatePicker"
                                            custom-class="has-text-centered date-input"
                                            :min-date="monthStart"
                                            :max-date="maxDate"
                                            icon="calendar"
                                            placeholder="Click to select..."
                                            :disabled="isEditingDisabled"
                                            @input="dataUpdated"
                                            v-model="physicalInventoryDate">
                                        </b-datepicker>
                                    </b-field>
                                </div>
                            </div>
                            <br>
                            <div class="columns has-background-white-ter spaced-text-8">
                                <div class="column is-3 is-size-6 is-underlined">FUEL</div>
                                <div class="column is-3 is-underlined has-text-right"> Volume (gal)</div>
                                <div class="column is-3 is-underlined has-text-right"> Unit Cost</div>
                                <div class="column is-3 is-underlined has-text-right"> Total Cost</div>
                            </div>   

                           <div class="ml-2"  v-bind:key="index" v-for="(fuel,index) in fuelAssetItems">                   
                             <div class="control columns">
                                <div class="column is-3">
                                    {{ fuel.name[0].toUpperCase() + fuel.name.substr(1).toLowerCase() }} 
                                </div>
                                 <div class="column is-3 has-text-right">
                                    <div class="field">
                                        <b-field>
                                            <VeeInput
                                                type="number"
                                                v-model="fuel.multiplicand"
                                                name="multiplicand"
                                                :value="fuel.multiplicand"
                                                minFractionDigits="3"
                                                maxFractionDigits="3"
                                                :countryCurrencyCode="countryCurrencyCode"
                                                :editable="!isEditingDisabled"
                                                @input="dataUpdated"
                                                />
                                        </b-field>
                                    </div>
                                </div>                                
                                <div class="column is-3 has-text-right">
                                   <b-field >
                                        <VeeInput
                                            type="currency" 
                                            v-model="fuel.multiplier"
                                            name="multiplier"
                                            :value="fuel.multiplier"
                                            :countryCurrencyCode="countryCurrencyCode"
                                            :editable="!isEditingDisabled"
                                            @input="dataUpdated"
                                            />
                                    </b-field>
                                </div>                                
                                <div class="column is-3 has-text-right">
                                    {{ fuel.multiplicand * fuel.multiplier  | formatCurrency }}
                                </div>
                             </div>
                            </div>  

                            </section>
                        </div>
                        <div class="panel-heading has-text-weight-bold">
                            <div class="control columns">
                                <div class="column is-two-quarters spaced-text-8">Total Fuel Inventory</div>
                                
                                <div class="column is-one-quarter has-text-right">{{ formatNumberWithCommas(totalFuelInventoryVolume,3) }}</div>
                                <div class="column is-one-quarter has-text-right">
                                    {{ (totalFuelInventoryCost / totalFuelInventoryVolume ? +(totalFuelInventoryCost / totalFuelInventoryVolume).toFixed(2) : 0) | formatCurrency}} 
                                </div>
                                <div class="column is-one-quarter has-text-right" :class="{'has-text-danger': totalFuelInventoryCost < 0.00 }">

                                    {{ totalFuelInventoryCost | formatCurrency}} 

                                </div>
                            </div>
                            
                        </div>
                        <div class="panel-block">
                            <div class="tiny-font">** Month Average Profit per gal. {{fuelProfitPerGallon | formatCurrency}}</div>
                        </div>
                        <br>

                        <div class="panel-block">
                            <section class="control">
                            <div class="columns has-background-white-ter spaced-text-8">
                                <div class="column is-3 is-underlined">MERCHANDISE</div>
                                <div class="column is-3 is-underlined has-text-right"> Sales Price</div>
                                <div class="column is-3 is-underlined has-text-right"> Profit%</div>
                                <div class="column is-3 is-underlined has-text-right"> Total Cost</div>
                            </div>

                           <div class="ml-2"  v-bind:key="index" v-for="(dept,index) in merchAssetItems">                   
                                <div class="control columns">
                                    <div class="column is-3">
                                        {{dept.name}} 
                                        <span class="tiny-font" v-if="dept.name == 'Cigarette'">
                                            <br>({{ profitAndLossData.cigCloseCount}} @ {{ inventoryReconcileItems.cigAveragePrice | formatCurrency}} )
                                        </span>

                                    </div>
                                    <div class="column is-3 has-text-right">
                                        <div class="field">
                                            <b-field>
                                                <VeeInput
                                                    type="currency" 
                                                    v-model="dept.multiplicand"
                                                    name="multiplicand"
                                                    :value="dept.multiplicand"
                                                    :countryCurrencyCode="countryCurrencyCode"
                                                    :editable="!isEditingDisabled"
                                                    @input="dataUpdated"
                                                    />
                                            </b-field>
                                        </div>                                    
                                    </div>                                
                                    <div class="column is-3 has-text-right">
                                        <b-field>
                                                <VeeInput
                                                    type="number" 
                                                    v-model="dept.multiplier"
                                                    name="multiplier"
                                                    :value="dept.multiplier"
                                                    postfix="%"
                                                    :countryCurrencyCode="countryCurrencyCode"
                                                    :editable="!isEditingDisabled"
                                                    @input="dataUpdated"
                                                    />
                                        </b-field>
                                    </div>                                                                
                                    <div class="column is-3 has-text-right">
                                        {{ dept.cost | formatCurrency}}
                                    </div>
                                </div>

                            </div>  
                                                        
                            </section>
                        </div>
                        <div class="panel-heading has-text-weight-bold">
                            <div class="control columns">
                                <div class="column is-4 spaced-text-8"> Total Merchandise</div>
                                <div class="column is-3 has-text-right">
                                    {{ totalMerchandiseInventoryPrice | formatCurrency}} 
                                </div>
                                <div class="column is-2 has-text-right">
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': totalMerchandiseInventoryCost < 0.00 }">

                                    {{ totalMerchandiseInventoryCost | formatCurrency}} 

                                </div>
                            </div>
                        </div>
                        <br>                       
                        <div class="has-text-weight-bold panel-heading">                            
                            <div class="control columns is-mobile">
                                <div class="column is-7"> Total Physical Inventory</div>
                                <div class="column is-5  has-text-right" :class="{'has-text-danger': totalPhysicalInventory < 0.00 }">
                                    {{ totalPhysicalInventory  | formatCurrency }}
                                </div>
                            </div>                                                                                                                
                        </div>                                                                                                                                                                                                                                          
                    </div>
                    <br>
                    <br>
                    <div class="panel">
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8"> INVENTORY RECONCILIATION </div>
                            </div>
                        </div>
                        <div class="columns">
                                <div class="ml-3 column is-6 has-text-left">
                                    <span>Previous Inventory Date - </span>
                                    <span v-if="previousMonthlyAssets.physicalInventoryDate">{{ previousMonthlyAssets.physicalInventoryDate | formatDate }}</span>
                                    <span v-else>N/A</span>
                                </div>
                                <div class="mr-3 column is-size-6 has-text-right"> 
                                    Period: {{ this.salesDateRange[0] | formatDate }} - {{ this.salesDateRange[1] | formatDate }} 
                                </div>
                            </div>
                        <div class="columns">
                            <div class="column is-size-4 spaced-text-8"> &nbsp;&nbsp;<u>Fuel Inventory</u> </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-6"></div>
                                <div class="column is-3  has-text-right">
                                    <span class="div-border-bottom">Cost</span>
                                </div>
                                <div class="column is-3 has-text-right">
                                    <span class="div-border-bottom">Sales Price</span>
                                </div>
                            </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-6">Previous Physical Balance
                                    <span class="tiny-font">
                                        <br>({{ formatNumberWithCommas(inventoryReconcileItems.prevFuelInventoryVolume,3) }} gal. @  {{ inventoryReconcileItems.prevFuelAvgCost  |formatCurrency }} )
                                    </span>
                                </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.prevFuelInventoryCost < 0.00 }">
                                    {{ inventoryReconcileItems.prevFuelInventoryCost  | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.prevFuelInventoryPrice < 0.00 }">
                                    {{ inventoryReconcileItems.prevFuelInventoryPrice | formatCurrency }}
                                </div>
                            </div>
                        </div>
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6">Add - Purchases 
                                    <span class="tiny-font">
                                        <br>({{ formatNumberWithCommas(profitAndLossData.totalFuelVolumePurchased, 3) }} gal. @  {{ inventoryReconcileItems.fuelAvgCost |formatCurrency }} )
                                    </span>
                                </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelPurchaseCost < 0.00 }">
                                    {{ inventoryReconcileItems.fuelPurchaseCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelPurchase < 0.00 }">
                                    {{ inventoryReconcileItems.fuelPurchasePrice | formatCurrency }}
                                </div>
                            </div>
                        </div>
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Less - Sales 
                                    <span class="tiny-font">                                        
                                        <br>({{ formatNumberWithCommas(totalFuelSalesVolume,3) }} gal. @ {{ inventoryReconcileItems.fuelAvgCost | formatCurrency}})
                                    </span>
                                </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelSalesCost < 0.00 }">
                                    {{ inventoryReconcileItems.fuelSalesCost  | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelSalesPrice < 0.00 }">
                                    {{ inventoryReconcileItems.fuelSalesPrice | formatCurrency  }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Book Balance
                                    <span class="tiny-font">
                                        <br> {{ formatNumberWithCommas(inventoryReconcileItems.fuelBookBalanceVolume,3) }} gal. @ {{  inventoryReconcileItems.fuelBookBalanceAvgCost | formatCurrency }}
                                    </span>
                                </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelBookBalanceCost < 0.00 }">
                                    {{ inventoryReconcileItems.fuelBookBalanceCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelBookBalancePrice < 0.00 }">
                                    {{ inventoryReconcileItems.fuelBookBalancePrice | formatCurrency }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Physical Inventory 
                                    <span class="tiny-font">
                                        <br>({{ formatNumberWithCommas(totalFuelInventoryVolume,3) }} gal. 
                                        @ Cost {{ inventoryReconcileItems.fuelBookBalanceAvgCost| formatCurrency}}
                                        @ Sales {{  inventoryReconcileItems.fuelAvgPrice }})
                                        
                                    </span>
                                </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelPhysicalInventoryCost < 0.00 }">
                                    {{ inventoryReconcileItems.fuelPhysicalInventoryCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelPhysicalInventoryPrice < 0.00 }">
                                    {{ inventoryReconcileItems.fuelPhysicalInventoryPrice | formatCurrency}}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Inventory Variance
                                    <span class="tiny-font">
                                        <br>({{ (((totalFuelInventoryCost - (totalPreviousPhysicalFuelInventory + totalFuelPurchase - totalFuelCost) )) / inventoryReconcileItems.fuelAvgCost) | formatFuelVolume }}  @ {{ inventoryReconcileItems.fuelBookBalanceAvgCost | formatCurrency}})
                                    </span>
                                </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelVarianceCost < 0.00 }">
                                    <!-- {{ totalFuelInventoryCost - totalPreviousPhysicalFuelInventory + totalFuelPurchase - totalFuelCost  | formatCurrency }} -->
                                    {{ inventoryReconcileItems.fuelVarianceCost  | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.fuelVarianceCost < 0.00 }">
                                    {{ inventoryReconcileItems.fuelVariance | formatCurrency}}
                                </div>
                            </div>
                        </div>
                        
                        <br>
                        <div class="columns">
                            <div class="column is-size-4 spaced-text-8"> &nbsp;&nbsp;<u>Merchandise Inventory</u> </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-6"></div>
                                <div class="column is-3  has-text-right">
                                    <span class="div-border-bottom">Cost</span>
                                </div>
                                <div class="column is-3 has-text-right">
                                    <span class="div-border-bottom">Sales Price</span>
                                </div>
                            </div>
                        </div>
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Previous Physical Balance</div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': (totalPreviousPhysicalMerchInventory - totalPreviousPhysicalCigInventory) < 0.00 }">
                                    {{ totalPreviousPhysicalMerchInventory - totalPreviousPhysicalCigInventory  | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right">
                                    {{ inventoryReconcileItems.merchPrevBalance | formatCurrency }}
                                </div>
                            </div>
                        </div>
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Add - Purchases </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': (totalInventoryPurchase - totalCigPurchase) < 0.00 }">
                                    {{ inventoryReconcileItems.merchPurchaseCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right">
                                    {{ inventoryReconcileItems.merchPurchasePrice  | formatCurrency }}
                                </div>
                            </div>
                        </div>
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Less - Sales </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.merchSalesCost < 0.00 }">
                                    {{ inventoryReconcileItems.merchSalesCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.merchSalesPrice < 0.00 }">
                                    {{ inventoryReconcileItems.merchSalesPrice | formatCurrency }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Book Balance</div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.merchBookBalanceCost < 0.00 }">
                                    {{ inventoryReconcileItems.merchBookBalanceCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.merchBookBalancePrice < 0.00 }">
                                    {{ inventoryReconcileItems.merchBookBalancePrice | formatCurrency }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Physical Inventory  
                                </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': (totalFuelInventoryCost - totalCigInventoryCost) < 0.00 }">
                                    {{ totalMerchandiseInventoryCost - totalCigInventoryCost| formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.merchPhysicalInventory < 0.00 }">
                                    {{ inventoryReconcileItems.merchPhysicalInventory  | formatCurrency }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Inventory Variance </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': (totalMerchandiseInventoryCost - totalCigInventoryCost - (totalPreviousPhysicalMerchInventory - totalPreviousPhysicalCigInventory + totalInventoryPurchase - totalCigPurchase - totalMerchandiseCost + totalCigCost) ) < 0.00 }">
                                    {{ (totalMerchandiseInventoryCost - totalCigInventoryCost - (totalPreviousPhysicalMerchInventory - totalPreviousPhysicalCigInventory + totalInventoryPurchase - totalCigPurchase - totalMerchandiseCost + totalCigCost) )  | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.merchVariance < 0.00 }">
                                    {{ inventoryReconcileItems.merchVariance | formatCurrency }}
                                </div>
                            </div>
                        </div>                        
                        
                        <br>
                        <div class="columns">
                            <div class="column is-size-4 spaced-text-8"> &nbsp;&nbsp;<u>Cigarette Inventory</u> </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-6"></div>
                                <div class="column is-3  has-text-right">
                                    <span class="div-border-bottom">Cost</span>
                                </div>
                                <div class="column is-3 has-text-right">
                                    <span class="div-border-bottom">Sales Price</span>
                                </div>
                            </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Previous Physical Balance</div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.prevCigInventoryCost < 0.00 }">
                                    {{ inventoryReconcileItems.prevCigInventoryCost  | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.prevCigInventoryPrice < 0.00 }">
                                    {{ inventoryReconcileItems.prevCigInventoryPrice | formatCurrency }}
                                </div>
                            </div>
                        </div>
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Add - Purchase</div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.cigPurchaseCost < 0.00 }">
                                    {{ inventoryReconcileItems.cigPurchaseCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.cigPurchasePrice < 0.00 }">
                                    {{ inventoryReconcileItems.cigPurchasePrice | formatCurrency }}                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Less - Sales</div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.cigSalesCost < 0.00 }">
                                    {{ inventoryReconcileItems.cigSalesCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.cigSalesPrice < 0.00 }">
                                    {{ inventoryReconcileItems.cigSalesPrice | formatCurrency }}                                    
                                </div>
                            </div>
                        </div>

                        <div class="panel-block panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Book Balance</div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.cigBookBalance < 0.00 }">
                                    {{ inventoryReconcileItems.cigBookBalance | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.cigBookBalancePrice < 0.00 }">
                                    {{ inventoryReconcileItems.cigBookBalancePrice | formatCurrency }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Physical Inventory  
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': totalFuelInventoryCost < 0.00 }">
                                    {{ totalCigInventoryCost| formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.cigTotalInventoryPrice < 0.00 }">
                                    {{ inventoryReconcileItems.cigTotalInventoryPrice | formatCurrency }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Inventory Variance </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.cigVariance < 0.00 }">
                                    {{ inventoryReconcileItems.cigVariance  | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.cigVariancePrice < 0.00 }">
                                    {{ inventoryReconcileItems.cigVariancePrice | formatCurrency }}
                                </div>
                            </div>
                        </div>  

                        <hr>
                        <div class="columns">
                            <div class="column is-size-4 spaced-text-8"> &nbsp;&nbsp;<u>Total Inventory</u> </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-6"></div>
                                <div class="column is-3  has-text-right">
                                    <span class="div-border-bottom">Cost</span>
                                </div>
                                <div class="column is-3 has-text-right">
                                    <span class="div-border-bottom">Sales Price</span>
                                </div>
                            </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Previous Balance</div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalPreviousPhysicalCost < 0.00 }">
                                    {{ inventoryReconcileItems.totalPreviousPhysicalCost  | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalPreviousPhysicalPrice < 0.00 }">
                                    {{ inventoryReconcileItems.totalPreviousPhysicalPrice | formatCurrency}}
                                </div>
                            </div>
                        </div>
                   
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Add - Purchases  </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalPurchaseCost < 0.00 }">
                                    {{ inventoryReconcileItems.totalPurchaseCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalPurchasePrice < 0.00 }">
                                    {{ inventoryReconcileItems.totalPurchasePrice | formatCurrency }}
                                </div>
                            </div>
                        </div>
                                           
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Less - Sales </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalSalesCost < 0.00 }">
                                    {{ inventoryReconcileItems.totalSalesCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalSalesPrice < 0.00 }">
                                    {{ inventoryReconcileItems.totalSalesPrice | formatCurrency }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Book Balance</div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalBookBalanceCost < 0.00 }">
                                    {{ inventoryReconcileItems.totalBookBalanceCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalBookBalancePrice < 0.00 }">
                                    {{ inventoryReconcileItems.totalBookBalancePrice | formatCurrency }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-6"> Physical Inventory  
                                </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalPhysicalCost < 0.00 }">
                                    {{ inventoryReconcileItems.totalPhysicalCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalPhysicalPrice < 0.00 }">
                                    {{ inventoryReconcileItems.totalPhysicalPrice | formatCurrency }}
                                </div>
                            </div>
                        </div>

                        <div class="panel-block panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-6"> Inventory Variance </div>
                                <div class="column is-3  has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalVarianceCost < 0.00 }">
                                    {{ inventoryReconcileItems.totalVarianceCost | formatCurrency }}
                                </div>
                                <div class="column is-3 has-text-right" :class="{'has-text-danger': inventoryReconcileItems.totalVariancePrice < 0.00 }">
                                    {{ inventoryReconcileItems.totalVariancePrice | formatCurrency}}
                                </div>
                            </div>
                        </div> 
                    </div>                        
                </div>
             
                <!-- Column 2 -->
                <div class="column is-half">

                    <!-- ASSETS -->
                    <div class="panel">
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8">ASSETS</div>
                            </div>
                        </div>
                        <div class="ml-2 panel-block"  v-bind:key="index" v-for="(item,index) in currentAssetItems">                   
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters is-mobile">{{ item.name }}</div>
                                <div class="column is-two-quarter has-text-right">
                                    <div class="field">
                                        <b-field>
                                            <VeeInput
                                                type="currency" 
                                                v-model="item.multiplicand"
                                                name="multiplicand"
                                                :value="item.multiplicand"
                                                :countryCurrencyCode="countryCurrencyCode"
                                                :editable="!isEditingDisabled"
                                                @input="dataUpdated"
                                                />
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div class="panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters spaced-text-8">Total Liquid Assets</div>
                                <div class="column is-one-quarter has-text-right">
                                    <div class="field">
                                        <div class="control is-clearfix">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-one-quarter has-text-right" :class="{'has-text-danger': totalLiquidAssets < 0.00 }">

                                    {{ totalLiquidAssets | formatCurrency }} 

                                </div>
                            </div>
                        </div>
                        <div class="panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-7">Fuel Inventory </div>
                                <div class="column is-5 has-text-right">
                                    {{ (totalFuelInventoryCost ? totalFuelInventoryCost : 0)  | formatCurrency }}
                                </div>
                            </div>
                        </div>
                        <div class="panel-block">                            
                            <div class="control columns is-mobile">
                                <div class="column is-7">Merchandise Inventory </div>
                                <div class="column is-5 has-text-right">
                                    {{ totalMerchandiseInventoryCost  | formatCurrency }}
                                </div>
                            </div>
                        </div>
                        <div class="panel-block panel-heading  spaced-text-8">                            
                            <div class="control columns is-mobile">
                                <div class="column is-7">Total Assets </div>
                                <div class="column is-5 has-text-right">
                                    {{ totalAssets  | formatCurrency }}
                                </div>
                            </div>                                                                                                                
                        </div>                                                 
                    </div>
                    
                    <!-- LIABILITIES -->
                    <div class="panel">
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8">LIABILITIES </div>
                            </div>
                        </div>
                        <div class="ml-2 panel-block"  v-bind:key="index" v-for="(item,index) in currentLiabilityItems">                   
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters">{{ item.name }}</div>
                                <div class="column is-two-quarter has-text-right">
                                    <div class="field">
                                        <b-field>
                                            <VeeInput
                                                type="currency" 
                                                v-model="item.multiplicand"
                                                name="multiplicand"
                                                :value="item.multiplicand"
                                                :countryCurrencyCode="countryCurrencyCode"
                                                :editable="!isEditingDisabled"
                                                @input="dataUpdated"
                                                />
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters spaced-text-8">Total Current Liabilities</div>
                                <div class="column is-one-quarter has-text-right">
                                    <div class="field">
                                        <div class="control is-clearfix">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-one-quarter has-text-right" :class="{'has-text-danger': totalCurrentLiabilities > 0.00 }">

                                    {{ totalCurrentLiabilities | formatCurrency }} 

                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="panel-heading has-text-weight-bold">
                            <div class="control columns is-mobile">
                                <div class="column is-7 spaced-text-8">Net Business Equity</div>
                                <div class="column is-5 has-text-right" :class="{'has-text-danger': totalPaidAssetValue < 0.00 }">

                                    {{ totalPaidAssetValue | formatCurrency }} 

                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- OTHER MONTHLY INFO -->
                    <div class="panel">
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8">OTHER MONTHLY INFO </div>
                            </div>
                        </div>
                       
                        <div class="ml-2 panel-block" v-bind:key="item.id" v-for="(item) in sortArrayByProp(consignedAndNonInventory, 'name')">                 
                             <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    {{item.name}} 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    <div class="field">
                                        <b-field>
                                            <VeeInput
                                                type="currency" 
                                                v-model="item.multiplicand"
                                                :name="item.name"
                                                :value="item.multiplicand"
                                                :countryCurrencyCode="countryCurrencyCode"
                                                :editable="!isEditingDisabled"
                                                @input="dataUpdated"
                                                />
                                        </b-field>
                                    </div>                                    
                                </div>                                
                             </div>
                             
                        </div> 

                        <div class="ml-2 panel-block">    
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Bank Deposit - General
                                </div> 
                                    <div class="column is-two-quarter has-text-right">
                                    {{ (profitAndLossData.deposits ? profitAndLossData.deposits.bankDepositGeneral : 0)  | formatCurrency   }}
                                </div>                                
                            </div>
                        </div>
                        
                        <div class="ml-2 panel-block">    
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Bank Deposit - Other
                                </div> 
                                    <div class="column is-two-quarter has-text-right">
                                    {{ (profitAndLossData.deposits ? profitAndLossData.deposits.bankDepositLottery : 0)  | formatCurrency   }}
                                </div>                                
                            </div>
                        </div>

                        <div class="ml-2 panel-block">    
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Other Cash Disbursements
                                </div> 
                                    <div class="column is-two-quarter has-text-right">
                                    {{ (profitAndLossData.deposits ? profitAndLossData.deposits.bankDepositAtm : 0)  | formatCurrency   }}
                                </div>                                
                            </div>
                        </div>
                                         
                    </div>

                    <!-- INSTANT LOTTERY SUMMARY -->
                    <div class="panel">                    
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8">INSTANT LOTTERY SUMMARY</div>
                            </div>
                        </div>

                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Open Balance
                                </div> 
                                 <div class="column is-two-quarter has-text-right" :class="{'has-text-danger': lottery.openBalance < 0.00 }">
                                    {{ lottery.openBalance | formatCurrency }} 
                                    <!-- {{ totalPreviousLotteryInventory | formatCurrency }} -->
                                </div>                                
                             </div>
                        </div>  
                        
                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-two-quarters ">
                                    Add - Receipts
                                </div> 
                                 <div class="column is-two-quarter has-text-right" :class="{'has-text-danger': lottery.received < 0.00 }">
                                    {{ lottery.received | formatCurrency }} 
                                </div>                                
                             </div>
                        </div>
                        
                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-two-quarters ">
                                    Less - Returns
                                </div> 
                                 <div class="column is-two-quarter has-text-right" :class="{'has-text-danger': lottery.returned > 0.00 }">
                                    {{ lottery.returned * -1 | formatCurrency }} 
                                </div>                                
                             </div>
                        </div>            

                        <div class="ml-2 panel-block" v-if="lottery.instantSales > 0">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-two-quarters ">
                                    Less - Inside Sales
                                </div> 
                                 <div class="column is-two-quarter has-text-right" :class="{'has-text-danger': lottery.instantSales > 0.00 }">
                                    {{ (lottery.instantSales > 0 ? lottery.instantSales * -1 : 0)| formatCurrency }} 
                                </div>                                
                             </div>
                        </div>


                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="pl-5 column is-two-quarters ">
                                    Less - Outside Sales
                                </div> 
                                 <div class="column is-two-quarter has-text-right" :class="{'has-text-danger': lottery.machineWithdraw > 0.00 }">
                                    {{ (lottery.machineWithdraw > 0 ? lottery.machineWithdraw * -1 : 0)| formatCurrency }} 
                                </div>                                
                             </div>
                        </div>                        

                        <div class="ml-2 panel-heading ">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Book Balance
                                </div> 
                                 <div class="column is-two-quarter has-text-right" :class="{'has-text-danger': (lottery.openBalance + lottery.received - lottery.returned - lottery.instantSales - lottery.machineWithdraw) < 0.00 }">
                                    {{ (lottery.openBalance + lottery.received - lottery.returned - lottery.instantSales - lottery.machineWithdraw) | formatCurrency }} 
                                </div>                                
                             </div>
                        </div>


                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Close Balance
                                </div> 
                                 <div class="column is-two-quarter has-text-right" :class="{'has-text-danger': lottery.closeBalance < 0.00 }">
                                    {{ this.lottery.closeBalance | formatCurrency }} 
                                </div>                                
                             </div>
                        </div>


                        <div class="ml-2 panel-heading ">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Instant Lottery Variance
                                </div> 
                                 <div class="column is-two-quarter has-text-right" :class="{'has-text-danger': (this.lottery.closeBalance - (lottery.openBalance + lottery.received - lottery.returned - lottery.instantSales - lottery.machineWithdraw)) < 0.00 }">
                                    {{ (this.lottery.closeBalance - (lottery.openBalance + lottery.received - lottery.returned - lottery.instantSales - lottery.machineWithdraw)) | formatCurrency }} 
                                </div>                                
                             </div>
                        </div>
                        
                    </div>                    

                    <!-- ATM CASH SUMMMARY -->
                    <div class="panel">
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8">ATM CASH SUMMMARY</div>
                            </div>
                        </div>

                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Previous Balance 
                                </div> 
                                <div class="column is-two-quarter has-text-right">
                                    {{ previousAtmBalance | formatCurrency   }}
                                </div>                                
                            </div>
                        </div>
                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Cash Loaded
                                </div> 
                                <div class="column is-two-quarter has-text-right">
                                    {{ profitAndLossData.totalCashLoadedInAtm | formatCurrency   }}
                                </div>                                
                            </div>
                        </div>

                        <div class="ml-2 panel-block" v-bind:key="item.id" v-for="(item) in sortArrayByProp(atmBalanceRecord, 'name')">                 
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    {{item.name}} 
                                </div> 
                                <div class="column is-two-quarter has-text-right">
                                    <div class="field">
                                        <b-field>
                                            <VeeInput
                                                type="currency" 
                                                v-model="item.multiplicand"
                                                :name="item.name"
                                                :value="item.multiplicand"
                                                :countryCurrencyCode="countryCurrencyCode"
                                                :editable="!isEditingDisabled"
                                                @input="dataUpdated"
                                                />
                                        </b-field>
                                    </div>                                    
                                </div>                                
                            </div>
                        </div>

                        <div class="panel-block panel-heading  spaced-text-8">                            
                            <div class="control columns is-mobile">
                                <div class="column is-7">Cash Disbursed </div>
                                <div class="column is-5 has-text-right">
                                    {{ atmDisbursedAmount  | formatCurrency }}
                                </div>
                            </div>                                                                                                                
                        </div>

                        <div class="ml-2 panel-block" v-bind:key="item.id" v-for="(item) in sortArrayByProp(atmReportDisbursed, 'name')">                 
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    {{item.name}} 
                                </div> 
                                <div class="column is-two-quarter has-text-right">
                                    <div class="field">
                                        <b-field>
                                            <VeeInput
                                                type="currency" 
                                                v-model="item.multiplicand"
                                                :name="item.name"
                                                :value="item.multiplicand"
                                                :countryCurrencyCode="countryCurrencyCode"
                                                :editable="!isEditingDisabled"
                                                @input="dataUpdated"
                                                />
                                        </b-field>
                                    </div>                                    
                                </div>                                
                            </div>
                        </div>

                        <div class="panel-block panel-heading  spaced-text-8">                            
                            <div class="control columns is-mobile">
                                <div class="column is-7">Variance </div>
                                <div class="column is-5 has-text-right">
                                    {{ atmVariance | formatCurrency }}
                                </div>
                            </div>                                                                                                                
                        </div>
                    </div> 

                    <!-- CIGARETTE SUMMMARY -->
                    <div class="panel">
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8">CIGARETTE SUMMMARY</div>
                            </div>
                        </div>

                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Open Count 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    {{ profitAndLossData.previousCigaretteCloseCount   }}
                                </div>                                
                             </div>
                        </div>

                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Buy Count 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    {{ profitAndLossData.cigBuyCount   }}
                                </div>                                
                             </div>
                        </div>

                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Sale Count 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    {{ profitAndLossData.cigDeptSaleItemCount   }}
                                </div>                                
                             </div>
                        </div>

                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Close Count 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    {{ profitAndLossData.cigCloseCount   }}
                                </div>                                
                             </div>
                        </div>

                        <div class="ml-2 panel-heading panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Variance Count
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    {{ profitAndLossData.previousCigaretteCloseCount + profitAndLossData.cigBuyCount - profitAndLossData.cigDeptSaleItemCount -  profitAndLossData.cigCloseCount  }}
                                </div>                                
                             </div>
                        </div>

                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Buy Amount 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    <!-- {{ profitAndLossData.cigBuyAmount | formatCurrency  }} -->
                                    {{ totalCigPurchase | formatCurrency }}
                                </div>                                
                             </div>
                        </div>
                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Buy Cost/Pack  
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    <!-- {{ profitAndLossData.cigBuyAmount | formatCurrency  }} -->
                                    {{ totalCigPurchase / profitAndLossData.cigBuyCount  | formatCurrency }}
                                </div>                                
                             </div>
                        </div>

                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Sales Amount 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    {{ profitAndLossData.cigDeptSales | formatCurrency  }}
                                </div>                                
                             </div>
                        </div>  
                                                
                        <div class="ml-2 panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Sale Price/Pack 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    {{ profitAndLossData.cigDeptSales / profitAndLossData.cigDeptSaleItemCount | formatCurrency  }}
                                </div>                                
                             </div>
                        </div>  
                        
                        <div class="ml-2 panel-heading panel-block">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    Profit/Pack 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    {{ (profitAndLossData.cigDeptSales / profitAndLossData.cigDeptSaleItemCount) - (totalCigPurchase / profitAndLossData.cigBuyCount) | formatCurrency }}
                                </div>                                
                             </div>
                        </div>
                      
                    </div>
  
                    <!-- NOTABLE EXPENSES -->
                    <div class="panel">                    
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8">NOTABLE EXPENSES</div>
                            </div>
                        </div>

                        <div class="ml-2 panel-block" :key="expense.id" v-for="(expense) in sortArrayByProp(expenseRecords, 'expenseTypeName')">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    {{ expense.expenseTypeName }} 
                                </div> 
                                 <div class="column is-two-quarter has-text-right">
                                    {{ expense.amount | formatCurrency   }}
                                </div>                                
                             </div>
                            
                        </div>
                    </div> 
                    
                    <!-- INVENTORY PURCHASES -->
                    <div class="panel">                    
                        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                            <div class="columns">
                                <div class="column is-size-4 has-text-centered spaced-text-8">INVENTORY PURCHASES</div>
                            </div>
                        </div>

                        <div class="ml-2 panel-block" :key="expense.id" v-for="(expense) in sortArrayByProp(profitAndLossData.inventoryExpenseList, 'deptMapping')">
                            <div class="control columns is-mobile">
                                <div class="column is-two-quarters ">
                                    {{ expense.deptMapping }} 
                                </div> 
                                 <div class="column is-one-quarter has-text-right">
                                    {{ expense.expenseCost | formatCurrency   }}
                                </div>                                
                             </div>
                            
                        </div>
                    </div>

                </div>                
            </div>
        </div> 
        <month-end-pdf
                ref="pdfReport"
                :isShowPDFDialog="isShowPDFDialog"
                :monthSelected="monthSelected"
                :physicalInventoryDate="physicalInventoryDate"
                :currentAssetItems="currentAssetItems"
                :currentLiabilityItems="currentLiabilityItems"
                :fuelAssetItems="fuelAssetItems"
                :merchAssetItems="merchAssetItems"
                :nonInventory="nonInventory"
                :consignedGoods="consignedGoods"
                :atmRecord="atmRecord"
                :atmBalanceRecord="atmBalanceRecord"
                :atmReportDisbursed="atmReportDisbursed"
                :totalCashLoadedInAtm="profitAndLossData.totalCashLoadedInAtm"
                :previousAtmBalance="previousAtmBalance"
                :consignedAndNonInventory="consignedAndNonInventory"
                :previousMonthlyAssets="previousMonthlyAssets"
                :totalPreviousPhysicalInventory="totalPreviousPhysicalInventory"

                :totalPreviousPhysicalFuelInventoryVolume="totalPreviousPhysicalFuelInventoryVolume"
                :totalFuelSalesVolume="totalFuelSalesVolume"

                :totalPreviousPhysicalFuelInventory="totalPreviousPhysicalFuelInventory"
                :totalFuelPurchase="totalFuelPurchase"
                :totalFuelCost="totalFuelCost"
                :totalFuelInventoryCost="totalFuelInventoryCost"

                :totalPreviousPhysicalMerchInventory="totalPreviousPhysicalMerchInventory"
                :totalInventoryPurchase="totalInventoryPurchase"
                :totalMerchandiseCost="totalMerchandiseCost"
                :totalMerchandiseInventoryCost="totalMerchandiseInventoryCost"

                :totalPreviousPhysicalCigInventory="totalPreviousPhysicalCigInventory"
                :totalCigPurchase="totalCigPurchase"
                :totalCigCost="totalCigCost"
                :totalCigInventoryCost="totalCigInventoryCost"
                :inventoryReconcileItems="inventoryReconcileItems"

                :netAdjMonthBeg="netAdjMonthBeg"
                :netAdjMonthEnd="netAdjMonthEnd"
                :netAdjThisMonth="netAdjThisMonth"
                :inventoryBookValue="inventoryBookValue"
                :netInventoryDifference="netInventoryDifference"
                :expenseRecords="expenseRecords"
                :profitAndLossData="profitAndLossData"
                :lottery="lottery"
                @close-pdf-dialog="togglePDFDialog"
            >
        </month-end-pdf> 
        <VeeSavePane
            :cancelEnable="isEditing"
            :saveEnable="isEditing"
            :isEditing="isEditing"
            @cancel-edit="cancelEdit()"
            @save-edit="submitForm()"
            >
            
        </VeeSavePane>             
    </div>
</template>


<script>

import UserStore from "../../../../store/UserStore"
import MonthEndPdf from './MonthEndPDF'
import axios from 'axios'
import moment from 'moment'
import VeeInput from '../../../../components/app/VeeInput.vue'
import VeeSavePane from '../../../../components/app/VeeSavePane.vue'

export default {

   beforeRouteLeave (to, from, next) {
        
        if (this.isEditing) {
            this.cancelEdit()
        } else {
            next()
        }
    },

    components: {
        MonthEndPdf,
        VeeInput,
        VeeSavePane
    },

    metaInfo: {
        title: 'Month End Summary'
    },  

    data() {
        return {
            debug: false,
            falseValue: false,
            initialRun: true,
            reportDateChanged: false,
            loadingComponent: "",
            isShowPDFDialog: false,
            isFullPage: true,
            showNumberControls: false,
            printData: {},
            errorCode: {},
            errorMessageClosable: false,
            editDisabledMessageClosable: false,
            showErrorMessage: false,
            isMobileNativeDatePicker: false,
            isMonthFieldEditable: true,
            assetItemsNotFound: false,
            monthSelected: moment(this.monthSelected).subtract(1, 'months').startOf('month').toDate(),
            monthStart: moment(this.monthSelected).subtract(1, 'months').startOf('month').toDate(),
            monthEnd: new Date(),
            minDate: new Date('2020-01-01'),
            maxDate: new Date(),
            totalLiquidAssets: 0.00,
            salesDateRange: [],
            deptMappingsRevenue: [],
            premiumFuelProductNames: ["VPOWER","PREMIUM","SUPER","ULTIMATE"],
            profitAndLossData: {
                totalFuelVolumePurchased: 0.00,
                fuelSalesList: [],
                expensesList: [],
                salesTax: {},
                inventoryExpenseList: [],
                additionalIncomeList: [],
                deptRevenueAndCostList: [],
                },
            prevMonthlyReport: {},
            lotteryBooks: [],
            lotteryReturnedBooks: [],
            lottery: {
                openBalance: 0,
                instantSales: 0,
                instantPayout: 0,
                machineSales: 0,
                machineWithdraw: 0,
                closeBalance: 0,
                onlineSales: 0,
                onlinePayout: 0,
                bookBalance: 0,
                variance: 0
            },
            preMonthAdj: {
                startDate: '',
                endDate: '',
                totalMerchPurchase: 0.00,
                totalFuelPurchase: 0.00,
                totalMerchSales: 0.00,
                totalFuelSales: 0.00
            },
            postMonthAdj: {
                startDate: '',
                endDate: '',
                totalMerchPurchase: 0.00,
                totalFuelPurchase: 0.00,
                totalMerchSales: 0.00,
                totalFuelSales: 0.00
            },
            postAdjBeforeOrAfter: 'OnMonthEnd',
            preAdjBeforeOrAfter: 'OnMonthBegin',
            firstDayAfterLastPhysical: new Date(),
            fuelInventory: [],                
            deptSales: [],
            fuelTanks: [],
            inventoryPurchase: [],
            expensesToShow: ["Credit Card Fees","Payroll", "Payroll - Bonus", "Payroll - Manager", "Sales Tax Paid"],
            expenseRecords: [],
            totalGrossRevenue: 0.00,
            totalFuelRevenue: 0.00,
            totalFuelCost: 0.00,
            totalMerchandiseRevenue: 0.00,
            totalCostOfGoodsSold: 0.00,
            totalInventoryPurchase: 0.00,
            totalCigPurchase: 0.00,
            totalFuelPurchase: 0.00,
            cigProfitPercentage: 0,
            avgFuelCost: 0.00,
            fuelProfitPerGallon: 0.00,
            totalFuelVolumePurchased: 0.00,
            totalFuelInventoryCost: 0.00,
            totalFuelInventoryVolume: 0.00,
            // fuelAverageProfit: 0.00,
            fuelAverageCost: 0.00,
            totalFuelSalesVolume: 0,
            totalMerchandiseCost: 0.00,
            merchandiseProfitMargin: 0,
            totalCigCost: 0.00,
            totalMerchandiseInventoryCost: 0.00,
            totalMerchandiseInventoryPrice: 0.00,
            totalCigInventoryCost: 0.00,
            totalCigInventoryPrice: 0.00,
            totalAssets: 0.00,
            totalLiabilities: 0.00,
            totalPhysicalInventory: 0.00,
            totalPreviousPhysicalInventory: 0.00,
            totalPreviousPhysicalFuelInventory: 0.00,
            totalPreviousPhysicalFuelInventoryVolume: 0.000,
            totalPreviousPhysicalMerchInventory: 0.00,
            totalPreviousPhysicalCigInventory: 0.00,
            totalPreviousLotteryInventory: 0.00,
            totalPaidAssetValue: 0.00,
            totalCurrentLiabilities: 0.00,
            totalPreviousCashAssets: 0.00,
            totalPreviousFuelInventoryCost: 0.00,
            totalPreviousMerchandiseInventoryCost: 0.00,
            inventoryReconcileItems: {},
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            previousMonthlyAssets: {
                    monthlyAssetReportDetailDTOList: []
                },
            futureMonthlyAssets: {
                    monthlyAssetReportDetailDTOList: []
                },
            totalPreviousAssets: 0.00,
            previousAtmBalance: 0.00,
            physicalInventoryDate: moment().subtract(1,'months').endOf('month').toDate(),
            assetReportItems: [],
            currentAssetItems: [],
            fuelAssetItems: [],
            merchAssetItems: [],
            nonInventory: [],
            consignedAndNonInventory: [],
            consignedGoods: [],
            currentLiabilityItems: [],
            atmRecord: [],
            atmBalanceRecord: [],
            atmReportDisbursed: [],
            atmBalance: 0,
            atmDisbursedAmount: 0,
            atmVariance: 0,
            instantLotteryOpenAmount: 0,
            instantLotteryCloseAmount: 0,
            assetReportEmptyObject: {
                "monthlyAssetReportDetailDTOList": [],
                "physicalInventoryDate": moment().subtract(1, 'months').endOf('month').toDate(),
                "reportDate": new Date(),
                "storeId": UserStore.getters.getActiveStore.storeId,
                "userId": UserStore.getters.getUser.id,
                "userName": UserStore.getters.getUser.name,
                previousMonthlyAssetSummary: {
                    monthlyAssetReportDetailDTOList: []
                    },
                futureMonthlyAssetSummary: {
                    monthlyAssetReportDetailDTOList: []
                    },
                },
            assetReport: {
                previousMonthlyAssetSummary: {
                    monthlyAssetReportDetailDTOList: []
                },
                futureMonthlyAssetSummary: {
                    monthlyAssetReportDetailDTOList: []
                },
            },
            defaultAssetItems: [
                {
                "name": "Cash On Hand",
                "type": "A",
                "group": "CURRENT_ASSETS",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Cash In Safe",
                "type": "A",
                "group": "CURRENT_ASSETS",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Bank Account - General",
                "type": "A",
                "group": "CURRENT_ASSETS",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Bank Account - Lottery",
                "type": "A",
                "group": "CURRENT_ASSETS",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Bank Bank Account - Other",
                "type": "A",
                "group": "CURRENT_ASSETS",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Credit Card",
                "type": "A",
                "group": "CURRENT_ASSETS",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Other Accounts Receivables",
                "type": "A",
                "group": "CURRENT_ASSETS",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },                
                {
                "name": "Outstanding Checks",
                "type": "L",
                "group": "CURRENT_LIABILITIES",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Outstanding EFT",
                "type": "L",
                "group": "CURRENT_LIABILITIES",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Unpaid Merchandise Inventory",
                "type": "L",
                "group": "CURRENT_LIABILITIES",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Unpaid Fuel Inventory",
                "type": "L",
                "group": "CURRENT_LIABILITIES",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Other Accounts Payable",
                "type": "L",
                "group": "CURRENT_LIABILITIES",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                } ,
                {
                "name": "Instant Lottery Inventory",
                "type": "C",
                "group": "CONSIGNED_GOODS",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Prepaid Gasoline Tax per gal.",
                "type": "O",
                "group": "NON_INVENTORY",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Prepaid Gasoline Total Tax",
                "type": "O",
                "group": "NON_INVENTORY",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },                   
                {
                "name": "Prepaid Diesel Tax per gal.",
                "type": "O",
                "group": "NON_INVENTORY",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "Diesel Prepaid Total Tax",
                "type": "O",
                "group": "NON_INVENTORY",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "ATM Balance",
                "type": "O",
                "group": "NON_INVENTORY",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },
                {
                "name": "ATM Report Disbursed",
                "type": "O",
                "group": "NON_INVENTORY",
                "storeId": UserStore.getters.getActiveStore.storeId,
                "uom": "CURRENCY",
                "decimals": 2,
                "multiplicand": 0,
                "multiplier": 1,
                "multiplyRequired": false
                },                                          
            ],
            
        }
    },

    methods: {

        async fetchMonthlyData() {
            if (UserStore.getters.getActiveStore) {

                this.assetReport.monthlyAssetReportDetailDTOList = []

                if (this.debug) console.log("MonthlyData Fetching", this.salesDateRange[1])
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + '/monthlyAssetReport/' + this.salesDateRange[1]

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        this.assetReport = response.data.data
                        if (this.debug) console.log("MonthlyData Fetch Success", this.assetReport)
                        if (!this.assetReport.monthlyAssetReportDetailDTOList) {
                            this.assetReport.monthlyAssetReportDetailDTOList = []
                        }
                        
                        this.previousMonthlyAssets = (this.assetReport.previousMonthlyAssetSummary ? this.assetReport.previousMonthlyAssetSummary : {"monthlyAssetReportDetailDTOList":[]})
                        this.futureMonthlyAssets = (this.assetReport.futureMonthlyAssetSummary ? this.assetReport.futureMonthlyAssetSummary : {"monthlyAssetReportDetailDTOList":[]})
                        delete this.previousMonthlyAssets.previousMonthlyAssetSummary
                        delete this.previousMonthlyAssets.futureMonthlyAssetSummary
                        delete this.assetReport.previousMonthlyAssetSummary
                        delete this.assetReport.futureMonthlyAssetSummary

                        if (this.assetReport.physicalInventoryDate !== null) {
                            this.physicalInventoryDate = moment(this.assetReport.physicalInventoryDate).toDate()
                        } else { 
                            this.physicalInventoryDate = moment(this.salesDateRange[1]).toDate()
                        }

                        if (this.assetReport.id == null) {
                            this.$buefy.toast.open({
                                message: 'No Month End data found for ' + moment(this.salesDateRange[0]).format('MMMM YYYY'),
                                type: 'is-warning'
                            })
                            
                        } else {
                            this.$buefy.toast.open({
                                message: 'Month End data found for ' + moment(this.salesDateRange[0]).format('MMMM YYYY'),
                                type: 'is-info'
                            })
                        }

                    })
                    .catch( (err) => {                        
                        if (err.response.status === 404 || err.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'No Month End data found for ' + moment(this.salesDateRange[0]).format('MMMM YYYY'),
                                type: 'is-warning'
                            })
                            this.assetReport = this.assetReportEmptyObject
                            this.physicalInventoryDate = moment(this.salesDateRange[1]).endOf('month').toDate()
                            var today = new Date()
                            if (this.physicalInventoryDate > today) {
                                this.physicalInventoryDate = today
                            }
                            if (this.debug) console.log("MonthlyData Fetch 404 Not Found", this.assetReport)
                        }



                    })

                    await this.fetchAssetItems()
                        .then(() => {
                            if (this.assetReport.monthlyAssetReportDetailDTOList.length == 0) {
                                this.assetReport.monthlyAssetReportDetailDTOList = this.assetReportItems
                                if (this.debug) console.log("Just AssetReport",this.assetReport.monthlyAssetReportDetailDTOList)
                            }

                        })
                    await this.fetchRevenueData()

                    this.previousAtmBalance = 0

                    var atmReportItem = this.assetReportItems.find (item => item.name === "ATM Balance")

                    if (atmReportItem) {
                        var prevAtmRecord = this.previousMonthlyAssets.monthlyAssetReportDetailDTOList.find(asset => asset.monthlyAssetReportItemsId === atmReportItem.id)
                        if (prevAtmRecord) {
                            this.previousAtmBalance = prevAtmRecord.multiplicand
                        }
                    }
                        
            }
        },

        async fetchPrevMonthlyData() {
            if (UserStore.getters.getActiveStore) {
                var startDate = moment(this.salesDateRange[0]).add(-1, 'M').format("YYYY-MM-DD")

                if (this.debug) console.log("MonthlyData Fetching", this.salesDateRange[1])
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + '/monthlyAssetReport/' + startDate

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        this.prevMonthlyReport = response.data.data                         
                    })
                    .catch((err) => {
                        console.log("No Previous Monthly Report", err)
                    })
                }
        },

        async fetchAssetItems() {
            if (UserStore.getters.getActiveStore) {
                if (this.debug) console.log("Fetching AssetItems")
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + '/monthlyAssetReportItems'

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        this.assetReportItems = response.data.data
                        if (this.debug) console.log("AssetItem Fetch Success", this.assetReportItems)
                    })
                    .catch( (err) => {
                        this.assetReportItems = []
                        this.errorCode = err
                        if (this.debug) console.log("AssetReport Fetch Failed", this.errorCode)
                        
                    })
                    if (this.assetReportItems.length == 0) {
                        await this.createDefaultAssetItems()                            
                    }
            }
        },

        async fetchFuelTanks() {

            if (UserStore.getters.getActiveStore) {
                if (this.debug) console.log("Fetching FuelTanks")
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + '/tanks/status'

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        this.fuelTanks = response.data.data
                        if (this.debug) console.log("FuelTanks Fetch Success", this.fuelTanks)
                    })
                    .catch( (err) => {
                        this.fuelTanks = []
                        this.errorCode = err
                        if (this.debug) console.log("FuelTanks Fetch Failed", this.errorCode)
                        
                    })
            }

        },

        async fetchLotteryReturnedBooks() {
            if (UserStore.getters.getActiveStore) {
                if (this.debug) console.log("Fetching Returned Lottery Books")
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + '/lottery/books'
                    + "?status=RETURNED&returned_date_start="  + this.salesDateRange[0]
                    + "&returned_date_end=" + this.salesDateRange[1]

                await axios.get(url,
                {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        this.lotteryReturnedBooks = response.data.data
                        if (this.debug) console.log("Returned Lottery Books Fetch Success", this.lotteryReturnedBooks)
                    })
                    .catch( (err) => {
                        this.lotteryReturnedBooks = []
                        this.errorCode = err
                        if (this.debug) console.log("Returned Lottery Books Fetch Failed", this.errorCode)
                        
                    })
            }
        },

        async fetchLotteryBooks() {
            if (UserStore.getters.getActiveStore) {
                if (this.debug) console.log("Fetching Lottery Books")
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + '/lottery/books'
                    + "?created_at_start="  + this.salesDateRange[0]
                    + "&created_at_end=" + this.salesDateRange[1]

                await axios.get(url,
                {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        this.lotteryBooks = response.data.data
                        if (this.debug) console.log("Lottery Books Fetch Success", this.lotteryBooks)
                    })
                    .catch( (err) => {
                        this.lotteryBooks = []
                        this.errorCode = err
                        if (this.debug) console.log("Lottery Books Fetch Failed", this.errorCode)
                        
                    })
            }
        },

        async saveDefaultAssetItems() {
            if (UserStore.getters.getActiveStore) {
                if (this.debug) console.log("Saving Default Asset Items")
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + '/monthlyAssetReportItems'
                await axios.post(url,
                    this.assetReportItems,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( () => {
                        this.$router.go(0)                 
                    })
                    .catch( (err) => {
                        this.errorCode = err             
                    })
                if (this.debug) console.log("Saved Default Asset Items", this.assetReport)
            }
        },        

        async createDefaultAssetItems() {

            if (this.assetReportItems.length > 0) {
                return
            }
            if (this.debug) console.log("Creating Default AssetItems")
            this.$buefy.toast.open({
                    message: 'Adding default asset report items for your store.. please wait',
                    type: 'is-info',
                    duration: 6000,
                })
            this.assetReportItems = this.defaultAssetItems

            await this.fetchRevenueData()
                .then( () => {
                    this.profitAndLossData.deptRevenueAndCostList.forEach(item => {
                        if (item.storeMapping == "Taxes" || item.storeMapping == "Lottery") {
                            return
                        }
                        if (this.assetReportItems.length > 0) {
                            var asset = this.assetReportItems.find(o => o.name === item.storeMapping)
                            if (asset) {
                                return
                            }
                        }
                        

                        let inventoryAsset = {
                            "name": item.storeMapping,
                            "type": "A",
                            "group": "MERCH_ASSETS",
                            "storeId": UserStore.getters.getActiveStore.storeId,
                            "uom": "CURRENCY",
                            "decimals": 2,
                            "multiplicand": 0,
                            "multiplier": 1,
                            "multiplyRequired": true
                        }
                        if (inventoryAsset.name) {
                            if (this.debug) console.log("Adding Merchandise StoreMapping", item.storeMapping)
                            this.assetReportItems.push(inventoryAsset)
                        }
                        
                    })

                    if (this.fuelTanks.length > 0) {
                        this.fuelTanks.tankInventoryList.forEach(item => {
                            let inventoryAsset = {
                                "name": item.product,
                                "type": "A",
                                "group": "FUEL_ASSETS",
                                "storeId": UserStore.getters.getActiveStore.storeId,
                                "uom": "CURRENCY",
                                "decimals": 2,
                                "multiplicand": 0,
                                "multiplier": 1,
                                "multiplyRequired": true
                            }
                            if (inventoryAsset.name) {
                                var fuel = this.assetReportItems.find(o => o.name === item.product)
                                if (! fuel) {
                                    if (this.debug) console.log("Adding Fuel Product", item.product)
                                    this.assetReportItems.push(inventoryAsset)
                                }
                                
                            }
                        })
                    }

                })
            await this.saveDefaultAssetItems()

        },

        async loadProfitData() {

            this.totalFuelPurchase = 0
            this.totalFuelPurchase = this.profitAndLossData.inventoryExpenseList.reduce((accumulator, object) => {
                        return accumulator + (object.deptMapping == "Fuel" ? object.expenseCost : 0.00);
                    }, 0);
            
            this.totalInventoryPurchase = 0
            if (this.debug) console.log("Purchase", this.profitAndLossData.inventoryExpenseList)
            this.totalInventoryPurchase = this.profitAndLossData.inventoryExpenseList.reduce((accumulator, object) => {
                        return accumulator + (object.deptMapping == "Fuel" ? 0.00 : object.expenseCost);
                    }, 0);
            
            this.totalCigPurchase = 0
            this.totalCigPurchase = this.profitAndLossData.inventoryExpenseList.reduce((accumulator, object) => {
                        return accumulator + (object.deptMapping == "Cigarette" || object.deptMapping == "CigaretteCarton10"  ? object.expenseCost : 0.00);
                    }, 0);

            this.totalMerchandiseCost = 0

            this.totalMerchandiseCost = this.profitAndLossData.deptRevenueAndCostList.reduce((accumulator, object) => {
                        return accumulator + this.calculateMercandiseCost(object.netSalesValue, object.percentage)
                    }, 0);   
                                
            if (isNaN(this.totalMerchandiseCost)) {
                this.totalMerchandiseCost = 0
            }

            if (this.debug) console.log("Sales",this.profitAndLossData.deptRevenueAndCostList)
            this.totalCigCost = 0.0

            this.totalCigCost = this.profitAndLossData.deptRevenueAndCostList.reduce((accumulator, object) => {
                        return accumulator + ((object.storeMapping == "Cigarette" || object.storeMapping == "CigaretteCarton10")  ? this.calculateMercandiseCost(object.netSalesValue, object.percentage) : 0.00);
                    }, 0);

            if (isNaN(this.totalCigCost)) {
                this.totalCigCost = 0
            }

            this.totalFuelSalesVolume = 0
            this.totalFuelSalesVolume = this.profitAndLossData.fuelSalesList.reduce((accumulator, object) => {
                        return accumulator +  object.saleVolume;
                    }, 0);

            this.avgFuelCost = 0
            this.avgFuelCost = Math.round(this.totalFuelPurchase / this.profitAndLossData.totalFuelVolumePurchased * 100) / 100
            if (isNaN(this.avgFuelCost)) {
                this.avgFuelCost = 0
            }

            this.expenseRecords = []
            this.profitAndLossData.expensesList.forEach((expense) => {
                if (this.expensesToShow.includes(expense.expenseTypeName)) {
                    this.expenseRecords.push(expense)
                }
            })

            this.expenseRecords.push({
                "expenseTypeName": "Tax - State Sales Tax Paid",
                "amount":  this.profitAndLossData.salesTax.salesTaxPaid                
            })

            this.totalFuelRevenue = 0
            this.totalFuelVolume = 0
            this.profitAndLossData.fuelSalesList.forEach(fuel => {
                this.totalFuelRevenue += fuel.saleAmount
                this.totalFuelVolume += fuel.saleVolume
            })

            this.fuelProfitPerGallon = ( parseFloat(this.totalFuelRevenue) / parseFloat(this.totalFuelSalesVolume)) - parseFloat(this.avgFuelCost)

            this.profitAndLossData.fuelSalesList.forEach(fuel => {
                fuel.costPerGal = (fuel.saleAmount / fuel.saleVolume - this.fuelProfitPerGallon).toFixed(2)
                fuel.costAmount = (fuel.saleVolume * fuel.costPerGal).toFixed(2)
            })

            this.totalFuelCost = this.profitAndLossData.fuelSalesList.reduce((sum, item) => sum + parseFloat(item.costAmount),0,)
            if (isNaN(this.totalFuelCost)) {
                this.totalFuelCost = 0
            }

            this.lottery.instantSales = this.profitAndLossData.lottery.instantLotterySales
            this.lottery.instantPayout = this.profitAndLossData.lottery.instantLotteryPaidOut
            this.lottery.onlineSales = this.profitAndLossData.lottery.onlineLotterySales
            this.lottery.onlinePayout = this.profitAndLossData.lottery.onlineLotteryPaidOut
            this.lottery.machineWithdraw = this.profitAndLossData.totalLotteryMachineWithdraw
            this.lottery.machineSales = this.profitAndLossData.totalLotteryMachineSold
            this.lottery.onlineCancel = this.profitAndLossData.totalOnlineLottoCancel


        },

        async loadAssetItems() {
            if (this.debug) console.log("Loading Asset Report Items to sections")
            if (this.debug) console.log("Monthly Report Items",this.assetReport.monthlyAssetReportDetailDTOList)
            if (!this.assetReport.monthlyAssetReportDetailDTOList) {
                this.assetReport = this.assetReportEmptyObject
            }

            await this.loadProfitData()

            this.currentAssetItems = this.assetReportItems.filter((item) => {
                return item.group === 'CURRENT_ASSETS'
            })            
            this.currentAssetItems.forEach(item => {
                var reportItem = this.assetReport.monthlyAssetReportDetailDTOList.find(c => c.monthlyAssetReportItemsId == item.id)
                if (!reportItem) {
                    item.multiplicand = 0
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.monthlyAssetReportItemsId = item.id
                } else {
                    item.monthlyAssetReportItemsId = item.id
                    item.multiplicand = reportItem.multiplicand
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.id = null
                }
                if (this.debug) console.log("Current Asset item", item)

            })

            this.currentLiabilityItems = this.assetReportItems.filter((item) => {
                return item.group === 'CURRENT_LIABILITIES'
            })
            this.currentLiabilityItems.forEach(item => {
                var reportItem = this.assetReport.monthlyAssetReportDetailDTOList.find(c => c.monthlyAssetReportItemsId == item.id)
                if (!reportItem) {
                    item.multiplicand = 0
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.monthlyAssetReportItemsId = item.id
                } else {
                    item.multiplicand = reportItem.multiplicand
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.monthlyAssetReportItemsId = item.id
                    item.id = reportItem.id
                }
            })
            
            this.fuelAssetItems = this.assetReportItems.filter((item) => {
                return item.group === 'FUEL_ASSETS'
            })

            this.fuelAssetItems.forEach(item => {
                var reportItem = this.assetReport.monthlyAssetReportDetailDTOList.find(c => c.monthlyAssetReportItemsId == item.id)
                if (reportItem) {
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.multiplicand = (reportItem.multiplicand ? reportItem.multiplicand : 0)
                    item.monthlyAssetReportItemsId = item.id
                } else {
                    var fuel = this.fuelTanks.tankInventoryList.find(c => c.product.toLowerCase() == item.name.toLowerCase())
                    if (fuel) {
                        item.multiplicand = 0
                        item.multiplier = 0
                        var fuelItem = this.profitAndLossData.fuelSalesList.find(fuel => fuel.product.toLowerCase() == item.name.toLowerCase())
                        if (fuelItem) {
                            item.multiplier = parseFloat((fuelItem.cashPrice - this.fuelProfitPerGallon).toFixed(2))
                        } else {
                            this.profitAndLossData.fuelSalesList.forEach((prod) => {
                                if (this.premiumFuelProductNames.includes(prod.product)) {
                                    item.multiplier = parseFloat((prod.cashPrice - this.fuelProfitPerGallon).toFixed(2))
                                    if (this.debug) console.log("Found Premium Product",prod.product, item.multiplier,prod.cashPrice, prod.fuelProfitPerGallon, prod)
                                }
                            })
                        }
                        
                        var inventory = this.fuelInventory.find(fuel => fuel.product == item.name)
                        if (inventory) {
                            item.multiplicand = (inventory.endingVolume ? inventory.endingVolume : inventory.endingVolumePOS)
                        }
                        item.monthlyAssetReportItemsId = item.id
                        if (this.debug) console.log ("Fuel Product", fuel.product, item.name)
                    } else {
                        item.multiplier = 0
                        item.multiplicand = 0
                        item.monthlyAssetReportItemsId = null
                        item.id = null
                        if (this.debug) console.log ("Fuel Product Not Found", item.name, this.profitAndLossData.fuelSalesList)
                    }
                }
                item.cost = item.multiplier * item.multiplicand
            })

            this.merchAssetItems = this.assetReportItems.filter((item) => {
                return item.group === 'MERCH_ASSETS'
            }) 

            var nonInv = this.assetReportItems.filter((item) => {
                return item.group === 'NON_INVENTORY'
            })

            var nonInvReport = this.sortArrayByProp(nonInv,'name')

            nonInvReport.forEach(item => {

                var reportItem = this.assetReport.monthlyAssetReportDetailDTOList.find(c => c.monthlyAssetReportItemsId == item.id)
                if (reportItem) {
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.multiplicand = reportItem.multiplicand
                    item.monthlyAssetReportItemsId = reportItem.monthlyAssetReportItemsId
                    item.id = reportItem.id
                    item.cost = item.multiplier * item.multiplicand
                } else {
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.multiplicand = 0
                    item.monthlyAssetReportItemsId = null
                    item.cost = 0
                }
                if (this.debug) console.log("Consigned item", item)
                if (item.multiplicand === null) item.multiplicand = 0.00
            })

            this.nonInventory = nonInvReport.filter((item) => {
                return item.group === 'NON_INVENTORY' && item.name !== "ATM Balance" && item.name !== "ATM Report Disbursed"
            })

            this.atmRecord = nonInvReport.filter((item) => {
                return item.group === 'NON_INVENTORY' && (item.name === "ATM Balance"  || item.name === "ATM Report Disbursed")
            })

            this.atmBalanceRecord = nonInvReport.filter((item) => {
                return item.group === 'NON_INVENTORY' && (item.name === "ATM Balance")
            })

            this.atmReportDisbursed = nonInvReport.filter((item) => {
                return item.group === 'NON_INVENTORY' && (item.name === "ATM Report Disbursed")
            })
            
            this.consignedGoods = this.assetReportItems.filter((item) => {
                    return item.group === 'CONSIGNED_GOODS'
            })

            if (this.debug) console.log(this.consignedGoods)

            this.consignedGoods.forEach(item => {
                var reportItem = this.assetReport.monthlyAssetReportDetailDTOList.find(c => c.monthlyAssetReportItemsId == item.id)
                if (reportItem) {
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.multiplicand = reportItem.multiplicand
                    item.monthlyAssetReportItemsId = reportItem.monthlyAssetReportItemsId
                    item.id = reportItem.id
                    item.cost = item.multiplier * item.multiplicand
                    if (item.name === "Instant Lottery Inventory") {
                        this.lottery.closeBalance = reportItem.multiplicand
                    }
                } else {
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.multiplicand = 0
                    item.monthlyAssetReportItemsId = null
                    item.cost = 0
                }
                if (this.debug) console.log("Consigned item", item)
                if (item.multiplicand === null) item.multiplicand = 0.00
            })

            this.consignedAndNonInventory = this.nonInventory.concat(this.consignedGoods)

            if (this.debug) console.log("AssetItems", this.assetReportItems)
            if (this.debug) console.log("Non-Inventory", this.nonInventory)
            if (this.debug) console.log("Consigned Goods", this.consignedGoods)

            this.merchAssetItems.forEach(item => {
                var reportItem = this.assetReport.monthlyAssetReportDetailDTOList.find(c => c.monthlyAssetReportItemsId == item.id)
                
                if (reportItem) {
                    item.multiplier = (item.multiplyRequired ? reportItem.multiplier : 1)
                    item.multiplicand = reportItem.multiplicand
                    item.monthlyAssetReportItemsId = reportItem.monthlyAssetReportItemsId
                    item.id = reportItem.id
                    item.cost = this.calculateMercandiseCost(item.multiplicand,item.multiplier)
                } else {
                    var merch = this.profitAndLossData.deptRevenueAndCostList.find(c => c.storeMapping.toLowerCase() == item.name.toLowerCase())
                    if (merch) {
                        item.multiplier = merch.percentage
                        item.multiplicand = 0
                        if (item.name == "Cigarette") {                            
                            item.multiplicand = parseFloat(((this.profitAndLossData.cigDeptSales / this.profitAndLossData.cigDeptSaleItemCount) * this.profitAndLossData.cigCloseCount).toFixed(2))
                        }
                        
                        item.monthlyAssetReportItemsId = item.id
                    } else {
                        item.multiplier = (item.multiplier ? item.multiplier : 0)
                        item.multiplicand = 0
                        item.monthlyAssetReportItemsId = null
                        item.id = null
                        item.cost = 0
                    }
                }
                
            })             

            this.totalPreviousPhysicalInventory = 0.00
            this.totalPreviousPhysicalFuelInventory = 0.00
            this.totalPreviousPhysicalFuelInventoryVolume = 0.000
            this.totalPreviousPhysicalMerchInventory = 0.00
            this.totalPreviousPhysicalCigInventory = 0.00
            this.lottery.openBalance = 0
            this.totalPreviousLotteryInventory = 0

            this.assetReportItems.forEach( item => {
                var reportItem = this.previousMonthlyAssets.monthlyAssetReportDetailDTOList.find( asset => asset.monthlyAssetReportItemsId === item.monthlyAssetReportItemsId)
                if (reportItem) {
                    if (item.group === "FUEL_ASSETS") {
                        if (this.debug) console.log(item.group, reportItem.multiplicand, reportItem.multiplier, reportItem.multiplicand * reportItem.multiplier)
                        this.totalPreviousPhysicalInventory += (parseFloat(reportItem.multiplicand) * parseFloat(reportItem.multiplier))
                        this.totalPreviousPhysicalFuelInventory += (parseFloat(reportItem.multiplicand) * parseFloat(reportItem.multiplier))
                        this.totalPreviousPhysicalFuelInventoryVolume += parseFloat(reportItem.multiplicand)
                    }
                    if (item.group === "MERCH_ASSETS") {
                        if (this.debug) console.log(item.group, item.name, reportItem.multiplicand, reportItem.multiplier, reportItem.multiplicand / (100 + reportItem.multiplier) / reportItem.multiplier)
                        this.totalPreviousPhysicalInventory += this.calculateMercandiseCost(reportItem.multiplicand,reportItem.multiplier)
                        this.totalPreviousPhysicalMerchInventory += this.calculateMercandiseCost(reportItem.multiplicand,reportItem.multiplier)
                        if (item.name === "Cigarette") {
                            this.totalPreviousPhysicalCigInventory += this.calculateMercandiseCost(reportItem.multiplicand,reportItem.multiplier)
                        }
                    }
                    if (item.group === "CONSIGNED_GOODS") {
                        if (item.name === "Instant Lottery Inventory") {
                            this.lottery.openBalance = reportItem.multiplicand
                        }
                    }

                    if (this.lotteryBooks.length > 0) {
                        this.lottery.received = this.lotteryBooks.reduce((sum, item) => sum + parseFloat(item.totalValue),0,)
                    }

                    if (this.lotteryReturnedBooks.length > 0) {
                        this.lottery.returned = this.lotteryReturnedBooks.reduce((sum, item) => sum + 
                            (this.getNumber(item.ticketCount) - 
                            this.getNumber(item.lastTicketSold)) * 
                            this.getNumber(item.pricePoint),0,)
                    }
                    
                }                
            })

            if (this.debug) console.log("TotalPreviousPhysicalInventory",this.totalPreviousPhysicalInventory)
            await this.reCalculate()
            if (this.debug) console.log("Loaded AssetItems")
            this.unsetIsLoading()                                

        },
        
        async fetchRevenueData() {
            if (UserStore.getters.getActiveStore) {
                this.profitAndLossData = {
                    totalFuelVolumePurchased: 0.00,
                    fuelSalesList: [],
                    expensesList: [],
                    salesTax: {},
                    inventoryExpenseList: [],
                    additionalIncomeList: [],
                    deptRevenueAndCostList: []
                }
                if (this.debug) console.log("Fetching RevenueData")
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/profit?size=50"  
                    + "&fromDate="+this.salesDateRange[0]+"&toDate="+this.salesDateRange[1]

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.profitAndLossData = response.data.data
                        if (this.debug) console.log("Fetch RevenueData Success", this.profitAndLossData)
                        this.fetchFuelInventory() 
                            .then(() => {
                                this.fetchLotteryBooks()
                                    .then(() => {
                                        this.fetchLotteryReturnedBooks()
                                            .then(() => {
                                                this.loadAssetItems()  
                                            })
                                    })
                                
                            })                     
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (this.debug) console.log("Fetch RevenueData Failed", err)
                        return err
                    })
            }
        },

        async fetchFuelInventory() {
            if (UserStore.getters.getActiveStore) {
                this.fuelInventory = []
                if (this.debug) console.log("FuelInventory Fetching")
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/tanks/fuelReconciliation?product=A"  
                    + "&fromDate="+this.salesDateRange[1]+"&toDate="+this.salesDateRange[1]
 
                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.fuelInventory = response.data.data 
                        if (this.debug) console.log("FuelInventory Fetch Success", this.fuelInventory)                     
                    })
                    .catch( (err) => {
                        
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        } else if (err.response.status === 404) {
                            this.fuelInventory = this.fuelTanks.tankInventoryList
                            if (this.debug) console.log("FuelInventory Fetch 404", this.fuelInventory)  
                        } else {
                            if (this.debug) console.log("FuelInventory Fetch Failed", err)                            
                        }
                        this.fuelInventory = []
                    })
                
            }

        },

        async fetchLotteryAudit() {
            if (UserStore.getters.getActiveStore) {
                if (this.debug) console.log("Lottery Audit Fetch")
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/lottery/spotcheckaudit/" + this.salesDateRange[1]
                    + "?startingDate="+this.salesDateRange[0]
 
                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        this.lottery.received = response.data.data.booksReceivedTotalValueBetweenAudits
                        this.lottery.returned = response.data.data.returnedTicketsTotalValueBetweenAudits
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                    })
            }

        },

        async preMonthRevenue() {
            if (UserStore.getters.getActiveStore) {
                if (this.debug) console.log("PreMonthRevenue Fetching")
                this.preMonthAdj = {
                    startDate: moment(this.salesDateRange[0]).add(-1, 'M').toDate(),
                    endDate: moment(this.previousMonthlyAssets.physicalInventoryDate).toDate(),
                    totalMerchPurchase: 0.00,
                    totalFuelPurchase: 0.00,
                    totalMerchSales: 0.00,
                    totalFuelSales: 0.00
                }

                if (this.previousMonthlyAssets.physicalInventoryDate == null) {
                    if (this.debug) console.log("PreMonthRevenue Fetching Canceled. Previos is null")
                    return
                } else {
                    if  (this.preMonthAdj.startDate.setHours(0,0,0,0) === this.preMonthAdj.endDate.setHours(0,0,0,0)) {
                        if (this.debug) console.log("PreMonthRevenue - Previous Physical date is same as month start date")
                        this.preAdjBeforeOrAfter = 'OnMonthEnd'
                        if (this.debug) console.log("PreMonthRevenue Fetching Canceled. Previous Inventory Date is same as Current Start Date")
                        return
                    } else {
                        var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                                + UserStore.getters.getActiveStore.storeId + "/sales/profit?size=50"  
                        
                        if (this.preMonthAdj.startDate.setHours(0,0,0,0) < this.preMonthAdj.endDate.setHours(0,0,0,0)) {
                            this.preAdjBeforeOrAfter = "Before"
                            url = url+"&fromDate="+moment(this.preMonthAdj.startDate).format('YYYY-MM-DD')+"&toDate="+moment(this.preMonthAdj.endDate).format('YYYY-MM-DD')
                        } else {
                            this.preAdjBeforeOrAfter = "After"
                            var temp = this.preMonthAdj.endDate
                            this.preMonthAdj.endDate = moment(this.preMonthAdj.startDate).subtract(1, 'd').toDate()
                            this.preMonthAdj.startDate = temp
                            url = url+"&fromDate="+moment(this.preMonthAdj.startDate).add(1,'d').format('YYYY-MM-DD')+"&toDate="+moment(this.preMonthAdj.endDate).format('YYYY-MM-DD')
                        }
                        if (this.debug) console.log("PreMonthRevenue - Previous Physical ", this.preAdjBeforeOrAfter, "Month Begin Date")
                    }
                }
                
                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        var preData = response.data.data

                        this.preMonthAdj.totalMerchPurchase = 0.00
                        preData.inventoryExpenseList.forEach((item) => {
                            if (item.deptMapping == "Fuel") {
                                this.preMonthAdj.totalFuelPurchase = (item.expenseCost > 0.00 ? item.expenseCost : 0.00)
                            } else {
                                this.preMonthAdj.totalMerchPurchase += (item.expenseCost > 0.00 ? item.expenseCost : 0.00)
                            }
                        })
                        if (this.preAdjBeforeOrAfter == "Before") {
                            this.preMonthAdj.totalFuelPurchase = (this.preMonthAdj.totalFuelPurchase ? this.preMonthAdj.totalFuelPurchase * -1 : 0)
                            this.preMonthAdj.totalMerchPurchase = (this.preMonthAdj.totalMerchPurchase ? this.preMonthAdj.totalMerchPurchase * -1 : 0)
                        }

                        this.preMonthAdj.totalFuelSales = preData.fuelSalesList.reduce((accum,item) => accum 
                                + item.saleVolume * (item.averageUnitPrice - this.fuelProfitPerGallon), 0)
                        this.preMonthAdj.totalMerchSales = preData.deptRevenueAndCostList.reduce((accum,item) => accum + item.cost, 0)


                        if (this.preAdjBeforeOrAfter == "Before") {
                            this.preMonthAdj.totalFuelSales = (this.preMonthAdj.totalFuelSales > 0.00 ? this.preMonthAdj.totalFuelSales  : 0.00)
                            this.preMonthAdj.totalMerchSales = (this.preMonthAdj.totalMerchSales > 0.00 ? this.preMonthAdj.totalMerchSales : 0.00)
                        }
                        
                        if (this.preAdjBeforeOrAfter == "After") {
                            this.preMonthAdj.totalFuelSales = (this.preMonthAdj.totalFuelSales > 0.00 ? this.preMonthAdj.totalFuelSales * -1 : 0.00)
                            this.preMonthAdj.totalMerchSales = (this.preMonthAdj.totalMerchSales > 0.00 ? this.preMonthAdj.totalMerchSales * -1 : 0.00)
                        }

                        
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        return err
                    })
                    if (this.debug) console.log("PreMonthRevenue Complete")
            }
        },

        async postMonthRevenue() {
            
            if (UserStore.getters.getActiveStore) {
                if (this.debug) console.log("PostMonthRevenue Fetching")
                this.postMonthAdj = {
                    startDate: '',
                    endDate: '',
                    totalMerchPurchase: 0.00,
                    totalFuelPurchase: 0.00,
                    totalMerchSales: 0.00,
                    totalFuelSales: 0.00
                }

                var monthEndDate = moment(this.salesDateRange[1]).toDate()
                if  (monthEndDate.setHours(0,0,0,0) === this.physicalInventoryDate.setHours(0,0,0,0)) {
                    this.postAdjBeforeOrAfter = 'OnMonthEnd'
                    return
                } else {
                    var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                            + UserStore.getters.getActiveStore.storeId + "/sales/profit?size=50"  

                    if (monthEndDate.setHours(0,0,0,0) < this.physicalInventoryDate.setHours(0,0,0,0)) {
                        this.postAdjBeforeOrAfter = "After"
                        this.postMonthAdj.startDate = moment(monthEndDate).add(1,'d')
                        this.postMonthAdj.endDate = moment(this.physicalInventoryDate)
                        url = url+"&fromDate="+this.postMonthAdj.startDate.format('YYYY-MM-DD')+"&toDate="+this.postMonthAdj.endDate.format('YYYY-MM-DD')
                    } else {
                        this.postAdjBeforeOrAfter = "Before"
                        this.postMonthAdj.endDate = moment(monthEndDate)
                        this.postMonthAdj.startDate = moment(this.physicalInventoryDate)
                        url = url+"&fromDate="+this.postMonthAdj.startDate.format('YYYY-MM-DD')+"&toDate="+this.postMonthAdj.endDate.format('YYYY-MM-DD')
                    }
                }

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        var postData = response.data.data

                        this.postMonthAdj.totalMerchPurchase = 0.00
                        postData.inventoryExpenseList.forEach((item) => {
                            if (item.deptMapping == "Fuel") {
                                this.postMonthAdj.totalFuelPurchase = (item.expenseCost > 0.00 ? item.expenseCost : 0.00)
                            } else {
                                this.postMonthAdj.totalMerchPurchase += (item.expenseCost > 0.00 ? item.expenseCost : 0.00)
                            }
                        })
                        if (this.postAdjBeforeOrAfter == "After") {
                            this.postMonthAdj.totalFuelPurchase = (this.postMonthAdj.totalFuelPurchase ? this.postMonthAdj.totalFuelPurchase * -1 : 0)
                            this.postMonthAdj.totalMerchPurchase = (this.postMonthAdj.totalMerchPurchase ? this.postMonthAdj.totalMerchPurchase * -1 : 0)
                        }

                        this.postMonthAdj.totalFuelSales = postData.fuelSalesList.reduce((accum,item) => accum 
                                + item.saleVolume * (item.averageUnitPrice - this.fuelProfitPerGallon), 0)
                        this.postMonthAdj.totalMerchSales = postData.deptRevenueAndCostList.reduce((accum,item) => accum + item.cost, 0)


                        if (this.postAdjBeforeOrAfter == "Before") {
                            this.postMonthAdj.totalFuelSales = (this.postMonthAdj.totalFuelSales > 0.00 ? this.postMonthAdj.totalFuelSales * -1 : 0.00)
                            this.postMonthAdj.totalMerchSales = (this.postMonthAdj.totalMerchSales > 0.00 ? this.postMonthAdj.totalMerchSales * -1 : 0.00)
                        }
                        
                        if (this.postAdjBeforeOrAfter == "After") {
                            this.postMonthAdj.totalFuelSales = (this.postMonthAdj.totalFuelSales > 0.00 ? this.postMonthAdj.totalFuelSales : 0.00)
                            this.postMonthAdj.totalMerchSales = (this.postMonthAdj.totalMerchSales > 0.00 ? this.postMonthAdj.totalMerchSales  : 0.00)
                        }

                        
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        return err
                    })
                    if (this.debug) console.log("PostMonthRevenue Complete")
            }
        },        

        async resetMonthData() {
            if (this.debug) console.log("Resetting monthly report data")
            const startOfMonth = moment(this.monthSelected).startOf('month').format('YYYY-MM-DD');
            const endOfMonth   = moment(this.monthSelected).endOf('month').format('YYYY-MM-DD');
            this.salesDateRange = [startOfMonth, endOfMonth]
            this.monthStart = moment(this.monthSelected).startOf('month').toDate()
            this.postAdjBeforeOrAfter = 'OnMonthEnd'
            this.preAdjBeforeOrAfter = 'OnMonthBegin'
            this.totalFuelRevenue = 0.00
            this.totalMerchandiseRevenue = 0.00
            this.totalGrossRevenue = 0.00
            this.totalFuelCost = 0.00
            this.totalFuelProfit = 0.00
            this.totalFuelVolume = 0.00
            this.totalMerchandiseCost = 0.00
            this.merchandiseProfitMargin = 0.00
            this.totalCostOfGoodsSold = 0.00
            this.totalProfit = 0.00
            this.totalOperatingExpense = 0.00
            this.totalOtherIncome = 0.00
            this.fuelProfitPerGallon = 0.00
            this.fuelAverageCost = 0.00
            this.totalInventoryPurchase = 0.00
            this.fuelPurchase = 0.00
            this.totalFuelPurchase = 0.00
            this.inventoryPurchase = 0.00
            this.totalFuelVolumePurchased = 0.00
            this.currentLiabilityItems = []
            this.assetReportItems = []
            this.merchAssetItems = []
            this.nonInventory = []
            this.fuelAssetItems = []
            this.totalCurrentLiabilities = 0.00
            this.totalLiquidAssets = 0.00
            this.totalFuelInventoryCost = 0.00
            this.totalFuelInventoryVolume = 0.00
            this.totalFuelSalesVolume = 0
            this.totalMerchandiseInventoryCost = 0.00
            this.totalCigInventoryCost = 0.00
            this.totalCigInventoryPrice = 0.00
            this.totalMerchandiseInventoryPrice = 0.00
            this.totalPreviousPhysicalInventory = 0.00
            this.profitAndLossData = {
                totalFuelVolumePurchased: 0.00,
                fuelSalesList: [],
                expensesList: [],
                salesTax: {},
                inventoryExpenseList: [],
                additionalIncomeList: [],
                deptRevenueAndCostList: [],
            }
            this.preMonthAdj = {
                    totalMerchPurchase: 0.00,
                    totalFuelPurchase: 0.00,
                    totalMerchSales: 0.00,
                    totalFuelSales: 0.00
                }
            this.postMonthAdj = {
                totalMerchPurchase: 0.00,
                totalFuelPurchase: 0.00,
                totalMerchSales: 0.00,
                totalFuelSales: 0.00
                }

            this.lottery = {
                openBalance: 0,
                instantSales: 0,
                instantPayout: 0,
                machineSales: 0,
                machineWithdraw: 0,
                closeBalance: 0,
                onlineSales: 0,
                onlinePayout: 0,
            },
            await this.reCalculate()
        },

        dataUpdated() {
            if (this.debug) console.log("DataUpdated.. ")
            UserStore.commit("setIsEditing",true)
            this.reCalculate()
        },
        
        async reCalculate() {

            if (this.debug) console.log("Recalculating")
            this.inventoryReconcileItems = {}
            this.totalFuelInventoryCost = 0
            this.totalFuelInventoryVolume = 0
            this.fuelAssetItems.forEach(item => {
                this.totalFuelInventoryCost += parseFloat(item.multiplier) * parseFloat(item.multiplicand)
                this.totalFuelInventoryVolume += parseFloat(item.multiplicand)
            })

            this.totalMerchandiseInventoryCost = 0
            this.totalMerchandiseInventoryPrice = 0
            this.merchAssetItems.forEach(item => {
                item.cost = this.calculateMercandiseCost(item.multiplicand,item.multiplier)
                this.totalMerchandiseInventoryCost += item.cost
                this.totalMerchandiseInventoryPrice += item.multiplicand
            })

            this.totalCigInventoryCost = 0
            this.totalCigInventoryPrice = 0
            this.merchAssetItems.forEach(item => {
                if (item.name == 'Cigarette') {
                    item.cost = this.calculateMercandiseCost(item.multiplicand,item.multiplier)
                    this.totalCigInventoryCost += item.cost
                    this.totalCigInventoryPrice += item.multiplicand
                    this.cigProfitPercentage = item.multiplier
                }
                
            })

            this.merchandiseProfitMargin = (this.totalMerchandiseInventoryCost - this.totalCigInventoryCost) /
                    (this.totalMerchandiseInventoryPrice - this.totalCigInventoryPrice)

            this.totalPhysicalInventory = parseFloat(this.totalFuelInventoryCost) + parseFloat(this.totalMerchandiseInventoryCost)

            if (isNaN(this.totalPhysicalInventory)) {
                this.totalPhysicalInventory = 0
            }

            this.totalLiquidAssets = this.currentAssetItems.reduce((total, item) => {
                return total + parseFloat(item.multiplicand)
            }, 0)

            this.totalCurrentLiabilities = this.currentLiabilityItems.reduce((total, item) => {
                return total + parseFloat(item.multiplicand)
            }, 0)

            this.totalAssets = parseFloat(this.totalPhysicalInventory) + parseFloat(this.totalLiquidAssets)

            this.totalPaidAssetValue = parseFloat(this.totalAssets) -
                                parseFloat(this.totalCurrentLiabilities)

            this.atmDisbursedAmount =  this.previousAtmBalance +
                    this.profitAndLossData.totalCashLoadedInAtm -
                    (this.atmRecord.length > 0 ? this.atmRecord[0].multiplicand : 0)

            this.atmVariance = this.atmDisbursedAmount - (this.atmReportDisbursed.length > 0 ? this.atmReportDisbursed[0].multiplicand : 0)

            
            // Fuel Reconciliation
            this.inventoryReconcileItems.prevFuelInventoryCost = this.totalPreviousPhysicalFuelInventory

            this.inventoryReconcileItems.prevFuelInventoryVolume = this.totalPreviousPhysicalFuelInventoryVolume

            this.inventoryReconcileItems.prevFuelAvgCost = +(this.inventoryReconcileItems.prevFuelInventoryCost / 
                this.inventoryReconcileItems.prevFuelInventoryVolume).toFixed(2)

            this.inventoryReconcileItems.fuelPurchaseVolume = this.profitAndLossData.totalFuelVolumePurchased

            this.inventoryReconcileItems.fuelPurchaseCost = this.totalFuelPurchase

            this.inventoryReconcileItems.fuelAvgCost = +(this.totalFuelPurchase / 
                this.profitAndLossData.totalFuelVolumePurchased).toFixed(2)

            this.inventoryReconcileItems.fuelAvgPrice = +(this.totalFuelRevenue / 
                this.totalFuelVolume).toFixed(2)                

            this.inventoryReconcileItems.prevFuelInventoryPrice = this.inventoryReconcileItems.prevFuelInventoryVolume * 
                this.inventoryReconcileItems.fuelAvgPrice

            this.inventoryReconcileItems.fuelPurchasePrice = this.profitAndLossData.totalFuelVolumePurchased * 
                this.inventoryReconcileItems.fuelAvgPrice                

            this.inventoryReconcileItems.fuelSalesVolume = this.totalFuelSalesVolume

            this.inventoryReconcileItems.fuelSalesCost = this.inventoryReconcileItems.fuelSalesVolume * 
                this.inventoryReconcileItems.fuelAvgCost * -1

            this.inventoryReconcileItems.fuelBookBalanceVolume = this.inventoryReconcileItems.prevFuelInventoryVolume +
                this.inventoryReconcileItems.fuelPurchaseVolume - this.inventoryReconcileItems.fuelSalesVolume 

            this.inventoryReconcileItems.fuelBookBalanceCost = this.inventoryReconcileItems.prevFuelInventoryCost +
                this.inventoryReconcileItems.fuelPurchaseCost + this.inventoryReconcileItems.fuelSalesCost

            this.inventoryReconcileItems.fuelBookBalanceAvgCost = +(this.inventoryReconcileItems.fuelBookBalanceCost / 
                this.inventoryReconcileItems.fuelBookBalanceVolume).toFixed(2)

            this.inventoryReconcileItems.fuelBookBalancePrice = this.inventoryReconcileItems.fuelBookBalanceVolume *
                this.inventoryReconcileItems.fuelAvgPrice

            this.inventoryReconcileItems.fuelVarianceCost = (this.totalFuelInventoryCost - 
                this.inventoryReconcileItems.fuelBookBalanceCost) 

            this.inventoryReconcileItems.fuelSalesPrice = this.totalFuelSalesVolume * 
                this.inventoryReconcileItems.fuelAvgPrice * -1

            this.inventoryReconcileItems.fuelBookBalancePrice = (this.totalPreviousPhysicalFuelInventoryVolume +
                this.profitAndLossData.totalFuelVolumePurchased - this.totalFuelSalesVolume) * 
                this.inventoryReconcileItems.fuelAvgPrice

            this.inventoryReconcileItems.fuelPhysicalInventoryCost = this.totalFuelInventoryCost

            this.inventoryReconcileItems.fuelPhysicalInventoryPrice = this.totalFuelInventoryVolume * 
                this.inventoryReconcileItems.fuelAvgPrice

            this.inventoryReconcileItems.fuelVariance = this.inventoryReconcileItems.fuelPhysicalInventoryPrice - 
                this.inventoryReconcileItems.fuelBookBalancePrice
                
            // Merchandise Reconciliation
            this.inventoryReconcileItems.merchPrevBalance = (this.totalPreviousPhysicalMerchInventory - 
                    this.totalPreviousPhysicalCigInventory) / this.merchandiseProfitMargin

            this.inventoryReconcileItems.merchPurchaseCost = this.totalInventoryPurchase - this.totalCigPurchase

            this.inventoryReconcileItems.merchPurchasePrice = (this.totalInventoryPurchase - 
                    this.totalCigPurchase) / this.merchandiseProfitMargin

            this.inventoryReconcileItems.merchSalesCost = (this.totalMerchandiseCost - this.totalCigCost) * -1

            this.inventoryReconcileItems.merchSalesPrice = ((this.totalMerchandiseCost - 
                this.totalCigCost) * -1) / this.merchandiseProfitMargin

            this.inventoryReconcileItems.merchBookBalanceCost = this.totalPreviousPhysicalMerchInventory - 
                this.totalPreviousPhysicalCigInventory + 
                this.totalInventoryPurchase - this.totalCigPurchase - 
                this.totalMerchandiseCost + this.totalCigCost

            this.inventoryReconcileItems.merchBookBalancePrice = (this.totalPreviousPhysicalMerchInventory - 
                this.totalPreviousPhysicalCigInventory + this.totalInventoryPurchase - 
                this.totalCigPurchase - this.totalMerchandiseCost + 
                this.totalCigCost) / this.merchandiseProfitMargin 

            this.inventoryReconcileItems.merchPhysicalInventory = this.totalMerchandiseInventoryPrice - 
                this.totalCigInventoryPrice

            this.inventoryReconcileItems.merchVariance = (this.totalMerchandiseInventoryCost - 
                this.totalCigInventoryCost - (this.totalPreviousPhysicalMerchInventory - 
                this.totalPreviousPhysicalCigInventory + this.totalInventoryPurchase - 
                this.totalCigPurchase - this.totalMerchandiseCost + 
                this.totalCigCost) ) / this.merchandiseProfitMargin


            // Cigarette Reconciliation
            this.inventoryReconcileItems.cigAveragePrice = +(this.profitAndLossData.cigDeptSales /  
                this.profitAndLossData.cigDeptSaleItemCount).toFixed(2)

            this.inventoryReconcileItems.cigProfitPercentage = 1 - +(this.cigProfitPercentage / 100).toFixed(2)

            this.inventoryReconcileItems.cigAverageCost = +(this.totalCigInventoryPrice / 
                this.inventoryReconcileItems.cigProfitPercentage).toFixed(2)

            this.inventoryReconcileItems.prevCigInventoryCost = this.totalPreviousPhysicalCigInventory

            this.inventoryReconcileItems.prevCigInventoryPrice = this.inventoryReconcileItems.prevCigInventoryCost /
                this.inventoryReconcileItems.cigProfitPercentage

            this.inventoryReconcileItems.cigPurchaseCost = this.totalCigPurchase  

            this.inventoryReconcileItems.cigPurchasePrice = this.totalCigPurchase / 
                this.inventoryReconcileItems.cigProfitPercentage

            this.inventoryReconcileItems.cigSalesCost = this.totalCigCost * -1
            
            this.inventoryReconcileItems.cigSalesPrice = this.inventoryReconcileItems.cigSalesCost / 
                this.inventoryReconcileItems.cigProfitPercentage

            this.inventoryReconcileItems.cigBookBalance = (this.totalPreviousPhysicalCigInventory + 
                this.totalCigPurchase - this.totalCigCost)

            this.inventoryReconcileItems.cigBookBalancePrice = this.inventoryReconcileItems.cigBookBalance / 
                this.inventoryReconcileItems.cigProfitPercentage

            this.inventoryReconcileItems.cigTotalInventoryPrice = this.totalCigInventoryCost / 
                this.inventoryReconcileItems.cigProfitPercentage

            this.inventoryReconcileItems.cigVariance = this.totalCigInventoryCost - 
                this.inventoryReconcileItems.cigBookBalance

            this.inventoryReconcileItems.cigVariancePrice = this.inventoryReconcileItems.cigVariance / 
                this.inventoryReconcileItems.cigProfitPercentage


            // Total Reconciliation

            this.inventoryReconcileItems.totalPreviousPhysicalCost = this.totalPreviousPhysicalInventory
            
            this.inventoryReconcileItems.totalPreviousPhysicalPrice = this.inventoryReconcileItems.prevFuelInventoryPrice +
                this.inventoryReconcileItems.merchPrevBalance + this.inventoryReconcileItems.prevCigInventoryPrice
            
            this.inventoryReconcileItems.totalPurchaseCost = this.inventoryReconcileItems.fuelPurchaseCost +
                this.inventoryReconcileItems.merchPurchaseCost +
                this.inventoryReconcileItems.cigPurchaseCost

            this.inventoryReconcileItems.totalPurchasePrice = this.inventoryReconcileItems.fuelPurchasePrice +
                this.inventoryReconcileItems.cigPurchasePrice +
                this.inventoryReconcileItems.merchPurchasePrice 
            
            this.inventoryReconcileItems.totalSalesCost = this.inventoryReconcileItems.fuelSalesCost +
                this.inventoryReconcileItems.cigSalesCost +
                this.inventoryReconcileItems.merchSalesCost
                
            this.inventoryReconcileItems.totalSalesPrice = this.inventoryReconcileItems.fuelSalesPrice +
                this.inventoryReconcileItems.cigSalesPrice +
                this.inventoryReconcileItems.merchSalesPrice

            this.inventoryReconcileItems.totalBookBalanceCost = this.inventoryReconcileItems.totalPreviousPhysicalCost +
                this.inventoryReconcileItems.totalPurchaseCost +
                this.inventoryReconcileItems.totalSalesCost

            this.inventoryReconcileItems.totalBookBalancePrice = this.inventoryReconcileItems.totalPreviousPhysicalPrice +
                this.inventoryReconcileItems.totalPurchasePrice +
                this.inventoryReconcileItems.totalSalesPrice

            this.inventoryReconcileItems.totalPhysicalCost = this.totalPhysicalInventory

            this.inventoryReconcileItems.totalPhysicalPrice = this.inventoryReconcileItems.fuelPhysicalInventoryPrice +
                this.inventoryReconcileItems.merchPhysicalInventory +
                this.inventoryReconcileItems.cigTotalInventoryPrice

            this.inventoryReconcileItems.totalVarianceCost = this.inventoryReconcileItems.totalPhysicalCost -
                this.inventoryReconcileItems.totalBookBalanceCost

            this.inventoryReconcileItems.totalVariancePrice = this.inventoryReconcileItems.totalPhysicalPrice -
                this.inventoryReconcileItems.totalBookBalancePrice

            // Update Lottery Inventory in the summary
            this.consignedAndNonInventory.forEach(item => {
                if (item.name === "Instant Lottery Inventory") {
                    this.lottery.closeBalance = item.multiplicand
                }
            })

            if (this.debug) console.log("After Calc, Monthly Report Items",this.assetReport.monthlyAssetReportDetailDTOList)
        },


        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.printData = this.updateSaveData()
                this.$refs.pdfReport.loadData()
            }
        },  

        updateSaveData() {
            var toSaveData = this.assetReportEmptyObject
            
            if (this.assetReport.id) {
                toSaveData.id = this.assetReport.id
            } else {
                toSaveData.id = null
            }
            toSaveData.storeId = UserStore.getters.getActiveStore.storeId
            toSaveData.userId = UserStore.getters.getUser.id
            toSaveData.reportDate = this.salesDateRange[1]
            toSaveData.physicalInventoryDate = this.physicalInventoryDate.toISOString().split('T')[0]

            toSaveData.monthlyAssetReportDetailDTOList = []

            this.currentAssetItems.forEach(item => {
                toSaveData.monthlyAssetReportDetailDTOList.push({
                    "id": item.id,
                    "monthlyAssetReportItemsId": item.monthlyAssetReportItemsId,
                    "multiplicand": item.multiplicand,
                    "multiplier": item.multiplier
                })
            })
            this.currentLiabilityItems.forEach(item => {
                toSaveData.monthlyAssetReportDetailDTOList.push({
                    "id": item.id,
                    "monthlyAssetReportItemsId": item.monthlyAssetReportItemsId,
                    "multiplicand": item.multiplicand,
                    "multiplier": item.multiplier
                })
            })
            this.fuelAssetItems.forEach(item => {
                toSaveData.monthlyAssetReportDetailDTOList.push({
                    "id": item.id,
                    "monthlyAssetReportItemsId": item.monthlyAssetReportItemsId,
                    "multiplicand": item.multiplicand,
                    "multiplier": item.multiplier
                })
            })
            this.merchAssetItems.forEach(item => {
                toSaveData.monthlyAssetReportDetailDTOList.push({
                    "id": item.id,
                    "monthlyAssetReportItemsId": item.monthlyAssetReportItemsId,
                    "multiplicand": item.multiplicand,
                    "multiplier": item.multiplier
                })
            })
            this.nonInventory.forEach(item => {
                toSaveData.monthlyAssetReportDetailDTOList.push({
                    "id": item.id,
                    "monthlyAssetReportItemsId": (item.monthlyAssetReportItemsId ? item.monthlyAssetReportItemsId : item.id),
                    "multiplicand": item.multiplicand,
                    "multiplier": 1
                })
            })
            this.atmRecord.forEach(item => {
                toSaveData.monthlyAssetReportDetailDTOList.push({
                    "id": item.id,
                    "monthlyAssetReportItemsId": (item.monthlyAssetReportItemsId ? item.monthlyAssetReportItemsId : item.id),
                    "multiplicand": item.multiplicand,
                    "multiplier": 1
                })
            })
            this.consignedGoods.forEach(item => {
                toSaveData.monthlyAssetReportDetailDTOList.push({
                    "id": item.id,
                    "monthlyAssetReportItemsId": (item.monthlyAssetReportItemsId ? item.monthlyAssetReportItemsId : item.id),
                    "multiplicand": item.multiplicand,
                    "multiplier": 1
                })
            })
            
            return toSaveData
        },

        submitForm() {
            var saveData = this.updateSaveData()

            if (UserStore.getters.getActiveStore) {
        
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/monthlyAssetReport"

                delete saveData.previousMonthlyAssetSummary
                delete saveData.futureMonthlyAssetSummary
                
                if (this.debug) console.log("Saving", saveData)
                
                if (saveData.id) {
                    axios.put(url,
                        saveData,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                                'Refresh-Token': UserStore.getters.getRefreshToken,
                                'correlation-id': this.$uuid.v1()
                            },                   
                        })
                        .then( (response) => {
                            if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                            this.$buefy.toast.open({
                                message: 'Monthly Report Updated',
                                type: 'is-success'
                            })

                            UserStore.commit("setIsEditing",false)
                        })
                        .catch( (err) => {
                            this.$buefy.toast.open({
                                message: 'Failed to Save Monthly Report ' + err,
                                type: 'is-danger'
                            })
                        })                
                } else {
                    saveData.monthlyAssetReportDetailDTOList.forEach(item => {
                        delete item.id
                    })
                    delete saveData.id
                    axios.post(url,
                        saveData,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                                'Refresh-Token': UserStore.getters.getRefreshToken,
                                'correlation-id': this.$uuid.v1()
                            },                   
                        })
                        .then( (response) => {
                            if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                            this.$buefy.toast.open({
                                message: 'Monthly Report Saved',
                                type: 'is-success'
                            })
                            // this.fetchMonthlyData()
                            UserStore.commit("setIsEditing",false)
                        })
                        .catch( (err) => {
                            this.$buefy.toast.open({
                                message: 'Failed to Update Monthly Report ' + err.response.status,
                                type: 'is-danger'
                            })
                        })
                
                }
            }            
            this.unsetIsLoading()
        },

        cancelEdit() {
            this.fetchMonthlyData()
            UserStore.commit("setIsEditing",false)
        },
 
        async beforeLeavingPage(event) {
            event = (event) ? event : window.event;
            if (this.isEditing) {
                event.preventDefault()
                event.returnValue = ""
                this.cancelEdit()
            }
        },      

        async setIsLoading() {
            if (this.debug) console.log("IsLoading set")
            this.loadingComponent = this.$buefy.loading.open({
                    container: this.isFullPage ? null : this.$refs.element.$el
                })
        },

        async unsetIsLoading() {
            if (this.debug) console.log("IsLoading Unset")
            this.loadingComponent.close()
        },

        monthFormatter(date) {
            return moment(date).format('MMMM YYYY')
        },

        async updateMonthlyData() {
            await this.setIsLoading()
            const startOfMonth = moment(this.monthSelected).startOf('month').format('YYYY-MM-DD');
            const endOfMonth   = moment(this.monthSelected).endOf('month').format('YYYY-MM-DD');
            this.salesDateRange = [startOfMonth, endOfMonth]
            await this.resetMonthData()
            await this.fetchLotteryAudit()
            await this.fetchMonthlyData()
            await this.unsetIsLoading()
        },

    },

    watch: {
        async monthSelected() {
            this.updateMonthlyData()
        },

        physicalInventoryDate() {
            this.postMonthRevenue()
        },
      
        
    },

    computed: {
        isEditing() {
            return UserStore.getters.getIsEditing
        },

        isEditingDisabled() {
            if (this.futureMonthlyAssets.id) {
                return true
            } else {
                return false
            }
        },
        
        transStartDate() {
            var startDate = this.salesDateRange[0]
            if (this.previousMonthlyAssets.physicalInventoryDate) {
                startDate = this.previousMonthlyAssets.physicalInventoryDate   
            }
            return startDate
        },

        transEndDate() {
            var endDate = this.salesDateRange[1]
            if (this.physicalInventoryDate) {
                endDate = this.physicalInventoryDate   
            }
            return endDate
        },

        netAdjThisMonth() {
            return parseFloat(this.totalInventoryPurchase) + 
                parseFloat(this.totalFuelPurchase) - 
                parseFloat(this.totalMerchandiseCost) -
                parseFloat(this.totalFuelCost)
        },

        netAdjMonthBeg() {
            return this.preMonthAdj.totalMerchSales
                + this.preMonthAdj.totalFuelSales
                + this.preMonthAdj.totalMerchPurchase
                + this.preMonthAdj.totalFuelPurchase
        },

        netAdjMonthEnd() {
            return this.postMonthAdj.totalMerchSales
                + this.postMonthAdj.totalFuelSales
                + this.postMonthAdj.totalMerchPurchase
                + this.postMonthAdj.totalFuelPurchase
        }, 


        inventoryBookValue() {
            return parseFloat(this.totalPreviousPhysicalInventory) 
                + this.netAdjThisMonth
                + this.netAdjMonthBeg
                + this.netAdjMonthEnd
        },

        netInventoryDifference() {

            return this.totalPhysicalInventory - this.inventoryBookValue

        },
    },
   
    async mounted() {

        UserStore.commit("setIsEditing",false)

        document.addEventListener("wheel", function(){
            if(document.activeElement.type === "number"){
                document.activeElement.blur();
            }
        })

        this.fetchFuelTanks()
        await this.updateMonthlyData()
    },

    created() {
        window.addEventListener('beforeunload',this.beforeLeavingPage)
    },  
        
}
</script>

<style>

.date-input  {
    background-color: rgb(250, 249, 246);
    border-radius: 0px;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 150px;
    border-color: rgb(37, 37, 36);
    border-width: 1px;
    height: 35px;
}


.spaced-text-8 {
  word-spacing: 1px;
}

.save-buttons-overlay {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 10;
  top: 0;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.lottery-seq-input input[type=number]  {

    background-color: white;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 80px;
    border-color: gold;
    border-width: 2px;
}
.lottery-seq-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    border-color: gray;
    border-width: 1px;
}

.tiny-font {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    height: 25px;
}

.month-date-selector {
    color: brown;
    font-weight: 900;
    font-size-adjust: 0.6;
}

.div-border-bottom {
    border-bottom: 2px;
    border-bottom-style: solid;
    border-bottom-color: black;
}
</style>